import { faCheck, faSort, faUndo } from '@fortawesome/pro-light-svg-icons'
import { observer } from 'mobx-react-lite'
import album from '../observables/album'
import AppScreen from './AppScreen'

import GalleryImage from './GalleryImage'
import IconText from './IconText'
import { Reorder, Reorderable, ReorderButton, ReorderCancelButton, ReorderContainer, ReorderDoneButton } from './Reorder'

const GalleryScreen = observer(() => {
  return (
    <AppScreen switchGalleryButton>
      <Reorder onReorder={(order) => { console.log('got new order', order) }}>
        <div className='level'>
          <div className='level-left' />
          <div className='level-right'>
            <ReorderButton>
              <IconText icon={faSort}>Rearrange</IconText>
            </ReorderButton>
            <ReorderCancelButton className='mr-3'>
              <IconText icon={faUndo}>Undo</IconText>
            </ReorderCancelButton>
            <ReorderDoneButton className='is-dark'>
              <IconText icon={faCheck}>Done Rearranging</IconText>
            </ReorderDoneButton>
          </div>
        </div>
        <div className='gallery-screen__pics'>
          <ReorderContainer>
            {
              album.pics.map((pic, index) => (
                <Reorderable key={pic.url} height='18.5em' width='13em' className='gallery-screen__pic'>
                  <GalleryImage pic={pic} index={index + 1} />
                </Reorderable>
              ))
            }
          </ReorderContainer>
        </div>
      </Reorder>
    </AppScreen>
  )
})

export default GalleryScreen

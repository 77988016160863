import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import TextareaAutosize from 'react-textarea-autosize'
import useModal from './useModal'

import album from '../observables/album'
import user from '../observables/user'
import { faCheck, faExclamation, faPencilAlt, faTimes, faTrashAlt, faUndo } from '@fortawesome/pro-light-svg-icons'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons'

import Button from './Button'

const ImageTitle = observer(({ imageId, imageAuthor, currentTitle, isMobile, userCanDelete }) => {
  const placeholderText = 'Untitled'
  const [editingMode, setEditingMode] = useState(false)
  const [menuState, setMenuState] = useState(false)
  const [modal, showModal] = useModal()
  const [title, setTitle] = useState(currentTitle || '')
  const editingInput = useRef()
  const buttonRef = useRef()

  const canEditTitle = (user.name === imageAuthor) || user.canDeleteComments

  const handleTitleClick = async () => {
    await setEditingMode(true)
    editingInput.current.focus()
  }

  const handleTitleChange = ev => {
    setTitle(ev.target.value)
  }

  const handleStopEditing = () => {
    setEditingMode(false)
    setTitle(currentTitle)
  }

  const handleSetTitle = async () => {
    await album.addImageTitle(imageId, title)
    setEditingMode(false)
  }

  const handleDeleteImage = async () => {
    const choice = await showModal(
      'Delete Image',
      "Are you sure you'd like to delete this image?",
      [{
        text: 'No',
        response: 'no',
        className: 'modal-cancel',
        icon: faUndo,
        iconProps: {
          size: '2x'
        },
        isCancel: true
      }, {
        text: 'Yes',
        className: 'is-dark',
        response: 'yes',
        icon: faCheck,
        iconProps: {
          size: '2x'
        }
      }]
    )
    if (choice === 'no') return
    await album.deleteImage(imageId)
  }

  const handleInputKeyPress = event => {
    if (event.key === 'Enter') buttonRef.current.click()
  }

  const handleOnHeightChange = (height, { rowHeight }) => {
    const valueRows = editingInput.current.scrollHeight / rowHeight
    const displayedRows = height / rowHeight

    if (displayedRows < valueRows) {
      editingInput.current.style.height = `${Math.ceil(valueRows * rowHeight)}px`
    }
  }

  const handleOffensiveContentReport = async () => {
    const choice = await showModal(
      'Report Image',
      "Are you sure you'd like to report this image?",
      [{
        text: 'No',
        response: 'no',
        className: 'modal-cancel',
        icon: faUndo,
        iconProps: {
          size: '2x'
        },
        isCancel: true
      }, {
        text: 'Yes',
        className: 'is-dark',
        response: 'yes',
        icon: faCheck,
        iconProps: {
          size: '2x'
        }
      }]
    )
    if (choice === 'no') return
    await album.offensiveContentReport(imageId, imageId)
    await showModal(
      'Content Report Submitted',
      'The image was successfully reported to the owner of this Photo Spaceship!',
      [{ text: 'OK', className: 'is-dark', response: 'ok', icon: faCheck, iconProps: { size: '2x' } }]
    )
    setMenuState(false)
  }

  const errorMap = {
    err2: 'Your session has expired (e2)',
    err3: 'An error occured while attempting to update the title (e3)',
    err4: 'An error occured while attempting to update the title (e4)'
  }

  const errorMap2 = {
    err2: 'Your session has expired (e2)',
    err3: 'An error occured while attempting to report this image (e3)',
    err4: 'An error occured while attempting to report this image (e4)'
  }

  const errorMap3 = {
    err2: 'Your session has expired (e2)',
    err3: 'An error occured while attempting to delete this image (e3)'
  }

  const titleContent = (currentTitle || placeholderText)
  const reportOffense = (
    <div className='comment__menu is-flex-grow-0'>
      <div className='comment__trigger' onClick={() => setMenuState(!menuState)}>
        <span className='icon'><FontAwesomeIcon icon={faEllipsisV} /></span>
      </div>
      <div className={classNames('comment__menu-items is-flex is-flex-direction-column', { open: menuState })}>
        <Button
          className={classNames('is-outlined is-white is-borderless is-radiusless is-flex is-justify-content-space-between')}
          onClick={handleOffensiveContentReport}
          errorMap={errorMap2}
        >
          <span>Report Image</span>
          <span className='icon is-small'><FontAwesomeIcon icon={faExclamation} /></span>
        </Button>
        {
          userCanDelete
            ? (
              <Button
                className={classNames('is-outlined is-white is-borderless is-radiusless is-flex is-justify-content-space-between')}
                onClick={handleDeleteImage}
                errorMap={errorMap3}
              >
                <span>Delete Image</span>
                <span className='icon is-small'><FontAwesomeIcon icon={faTrashAlt} /></span>
              </Button>
              )
            : null
        }
      </div>
    </div>
  )
  const titleMarkup = canEditTitle
    ? (
      <>
        <span className={classNames('icon-text', 'is-clickable', 'is-flex', 'is-align-content-center', 'is-flex-wrap-nowrap', { 'has-text-white': currentTitle }, { 'is-justify-content-center': isMobile }, { 'pt-2': isMobile })} onClick={handleTitleClick}>
          <span>{titleContent}</span>
          <span className='icon'>
            <FontAwesomeIcon icon={faPencilAlt} size='xs' />
          </span>
        </span>
        {reportOffense}
      </>
      )
    : <><span className='has-text-white'>{title}</span>{reportOffense}</>

  const editingMarkup = (
    <>
      <TextareaAutosize
        className='has-text-white'
        value={title}
        onChange={handleTitleChange}
        ref={editingInput}
        onKeyPress={handleInputKeyPress}
        maxLength={75}
        minRows={isMobile ? 2 : 1}
        onHeightChange={(height, opts) => handleOnHeightChange(height, opts)}
      />
      <div className={classNames('field is-grouped my-2')}>
        <div className='control is-expanded has-standard-basis'>
          <button type='button' className='button is-outlined is-white is-fullwidth' onClick={handleStopEditing}>
            <span>Cancel</span>
            <span className='icon is-small'><FontAwesomeIcon icon={faTimes} /></span>
          </button>
        </div>
        <div className='control is-expanded has-standard-basis'>
          <Button
            className={classNames('is-outlined is-white is-fullwidth')}
            onClick={handleSetTitle}
            buttonRef={buttonRef}
            errorMap={errorMap}
          >
            <span>Save</span>
            <span className='icon is-small'><FontAwesomeIcon icon={faCheck} /></span>
          </Button>
        </div>
      </div>
    </>
  )

  return (
    <div className={classNames('is-flex', { 'is-flex-direction-column': editingMode }, { 'image-title': !isMobile }, { 'mobile-image-title is-align-content-center is-justify-content-center': isMobile })}>
      {
        editingMode
          ? editingMarkup
          : titleMarkup
      }
      {modal}
    </div>
  )
})

export default ImageTitle

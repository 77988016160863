import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import screenSize from '../observables/screen-size'

const IconText = observer(({ icon, children, collapseMobile, ...props }) => {
  const collapsed = collapseMobile && screenSize.isMobile

  return (
    <>
      <span className={classNames('icon', { 'mr-1': !collapsed })}>
        <FontAwesomeIcon icon={icon} {...props} />
      </span>
      {
        !collapsed
          ? (
            <span>
              {children}
            </span>
            )
          : undefined
      }
    </>
  )
})

export default IconText

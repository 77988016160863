import classNames from 'classnames'
import React, { createContext, useEffect, useState, Children, useContext, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import Button from './Button'
import { EventEmitter } from 'events'

const pointers = {
  mouse: { x: 0, y: 0 },
  touch0: { x: 0, y: 0 }
}

window.addEventListener('mousemove', (event) => { pointers.mouse.x = event.clientX; pointers.mouse.y = event.clientY })
window.addEventListener('touchmove', (event) => {
  Array.from(event.changedTouches).forEach(touch => {
    if (touch.identifier !== event.touches[0].identifier) return

    pointers.touch0.x = touch.clientX
    pointers.touch0.y = touch.clientY
  })
})

const frameSource = new EventEmitter()
const frameLoop = () => {
  window.requestAnimationFrame(frameLoop)
  frameSource.emit('frame')
}
frameSource.setMaxListeners(0)

frameLoop()

export const ReorderContext = createContext({
  isReordering: false,
  wasReordering: false,
  setReordering: () => {},
  order: [],
  setOrder: () => {},
  startReordering: () => {},
  numChildren: 0,
  setNumChildren: () => {},
  randomId: '',
  grabbed: 0,
  setGrabbed: () => {},
  activePointer: '',
  setActivePointer: () => {},
  swapIntoPlace: () => {},
  onReorder: async () => {},
  alwaysOn: false
})

export const Reorder = ({ className, children, onReorder, alwaysOn }) => {
  const [isReordering, setReordering] = useState(false)
  const [wasReordering, setWasReordering] = useState(false)
  const [numChildren, setNumChildren] = useState(0)

  const [randomId] = useState(uuid())

  useEffect(() => {
    window.requestAnimationFrame(() => {
      setWasReordering(isReordering)
    })
  }, [isReordering])

  const [order, setOrder] = useState([])
  const [grabbed, setGrabbed] = useState(-1)
  const [activePointer, setActivePointer] = useState('')

  const context = {
    isReordering: isReordering || alwaysOn,
    wasReordering: wasReordering || alwaysOn,
    setReordering,
    order,
    setOrder,
    startReordering: () => {
      context.setReordering(true)
      context.setOrder(new Array(numChildren).fill().map((_value, index) => index))
    },
    numChildren,
    setNumChildren: (num) => numChildren === num ? null : setNumChildren(num),
    randomId,
    grabbed,
    setGrabbed,
    activePointer,
    setActivePointer,
    swapIntoPlace: (source, target) => {
      // console.log(`swapping ${source} into ${target}'s place (${magic.order.indexOf(target)})'`)

      const newOrder = magic.order.filter(index => index !== source)
      newOrder.splice(magic.order.indexOf(target), 0, source)
      setOrder(newOrder)
    },
    onReorder,
    alwaysOn
  }

  useEffect(() => {
    if (alwaysOn && order.length < numChildren) setOrder(new Array(numChildren).fill().map((_value, index) => index))
  }, [alwaysOn, order, numChildren])

  const [magic] = useState({
    order: []
  })
  magic.order = [...order]

  return (
    <ReorderContext.Provider value={context}>
      <div className={classNames('reorder', className)} id={randomId}>
        {children}
      </div>
    </ReorderContext.Provider>
  )
}

export const ReorderContainer = ({ children }) => {
  const childrenArray = Children.toArray(children)

  const context = useContext(ReorderContext)
  setTimeout(() => context.setNumChildren(childrenArray.length))

  return (
    <>
      {
        context.isReordering
          ? childrenArray.map((_value, arrayIndex) => {
              const index = context.order.length > arrayIndex ? context.order[arrayIndex] : arrayIndex
              return childrenArray[index] && React.cloneElement(childrenArray[index], { index })
            })
          : childrenArray.map((element, index) => React.cloneElement(element, { index }))
      }
    </>
  )
}

export const Reorderable = ({ className, children, width, height, index }) => {
  const context = useContext(ReorderContext)

  const handleClick = (event) => {
    if (context.isReordering && !context.alwaysOn) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const ref = useRef()

  const [x, setX] = useState(0)
  const [y, setY] = useState(0)

  const [grab, setGrab] = useState({ isGrabbed: false, x: 0, y: 0, pointer: '' })

  useEffect(() => {
    if (!context.isReordering || !grab.isGrabbed) return

    const updateGrabbedCoordinates = () => {
      const container = document.getElementById(context.randomId)
      if (!container) return

      const containerRect = container.getBoundingClientRect()

      const pointer = pointers[grab.pointer]

      setX(pointer.x - containerRect.x - grab.x)
      setY(pointer.y - containerRect.y - grab.y)
    }

    frameSource.on('frame', updateGrabbedCoordinates)
    return () => frameSource.removeListener('frame', updateGrabbedCoordinates)
  }, [context.isReordering, context.randomId, grab])

  useEffect(() => {
    if (!context.isReordering || grab.isGrabbed) return

    const updateCoordinates = () => {
      if (!ref.current) return
      const container = document.getElementById(context.randomId)
      if (!container) return

      const ownRect = ref.current.getBoundingClientRect()
      const containerRect = container.getBoundingClientRect()

      const newX = ownRect.x - containerRect.x
      const newY = ownRect.y - containerRect.y

      if (x !== newX) setX(newX)
      if (y !== newY) setY(newY)
    }

    frameSource.on('frame', updateCoordinates)
    return () => frameSource.removeListener('frame', updateCoordinates)
  }, [context.isReordering, context.randomId, grab.isGrabbed, x, y])

  const handleMouseGrab = (event) => {
    if (!context.isReordering) return
    const ownRect = ref.current.getBoundingClientRect()

    setGrab({
      isGrabbed: true,
      x: event.clientX - ownRect.x,
      y: event.clientY - ownRect.y,
      pointer: 'mouse'
    })
    context.setGrabbed(index)
    context.setActivePointer('mouse')
  }

  const handleTouchGrab = (event) => {
    if (!context.isReordering) return
    const ownRect = ref.current.getBoundingClientRect()

    Array.from(event.changedTouches).forEach(touch => {
      if (touch.identifier !== event.touches[0].identifier) return

      pointers.touch0.x = touch.clientX
      pointers.touch0.y = touch.clientY

      setGrab({
        isGrabbed: true,
        x: touch.clientX - ownRect.x,
        y: touch.clientY - ownRect.y,
        pointer: 'touch0'
      })
      context.setGrabbed(index)
      context.setActivePointer('touch0')

      event.preventDefault()
    })
  }

  useEffect(() => {
    const handleAbort = async () => {
      if (!grab.isGrabbed) return

      setGrab({ isGrabbed: false, x: 0, y: 0, pointer: '' })
      context.setGrabbed(-1)

      if (context.alwaysOn && typeof context.onReorder === 'function') {
        await context.onReorder(context.order)
        context.setOrder(new Array(context.numChildren).fill().map((_value, index) => index))
      }
    }

    window.addEventListener('mouseup', handleAbort)
    window.addEventListener('mouseleave', handleAbort)
    window.addEventListener('touchend', handleAbort)
    window.addEventListener('touchcancel', handleAbort)

    return () => {
      window.removeEventListener('mouseup', handleAbort)
      window.removeEventListener('mouseleave', handleAbort)
      window.removeEventListener('touchend', handleAbort)
      window.removeEventListener('touchcancel', handleAbort)
    }
  }, [grab, context])

  const handleDrag = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (!context.isReordering) {
      context.startReordering()

      const ownRect = ref.current.getBoundingClientRect()
      const x = event.clientX - ownRect.x
      const y = event.clientY - ownRect.y

      window.requestAnimationFrame(() => window.requestAnimationFrame(() => {
        setGrab({
          isGrabbed: true,
          x,
          y,
          pointer: 'mouse'
        })
        context.setGrabbed(index)
        context.setActivePointer('mouse')
      }))
    }
  }

  useEffect(() => {
    if (grab.isGrabbed || !~context.grabbed) return

    let wasHere = false

    const handleOtherEnter = () => {
      if (!ref.current) return
      const ownRect = ref.current.getBoundingClientRect()

      const pointer = pointers[context.activePointer]
      if (!pointer) return

      const horizontal = pointer.x > ownRect.left && pointer.x < ownRect.right
      const vertical = pointer.y > ownRect.top && pointer.y < ownRect.bottom

      const isHere = horizontal && vertical
      const justEntered = isHere && !wasHere
      wasHere = isHere

      if (justEntered) context.swapIntoPlace(context.grabbed, index)
    }

    frameSource.on('frame', handleOtherEnter)

    return () => frameSource.removeListener('frame', handleOtherEnter)
  }, [grab.isGrabbed, context, index])

  return (
    <div
      className={classNames('reorder__item-container')}
      style={{ width, height }}
      onClickCapture={handleClick}
      ref={ref}
      onMouseDown={handleMouseGrab}
      onTouchStart={handleTouchGrab}
      onDragStartCapture={handleDrag}
    >
      <div
        className={classNames('reorder__item', className, {
          'reorder__item--reordering': context.isReordering,
          'reorder__item--animated': context.isReordering && context.wasReordering && !grab.isGrabbed,
          'reorder__item--grabbed': grab.isGrabbed,
          'reorder__item--other-grabbed': !grab.isGrabbed && ~context.grabbed
        })}
        style={
          context.isReordering
            ? { width, height, left: 0, top: 0, transform: `translate(${x}px, ${y}px)` }
            : { width, height }
        }
      >
        {children}
      </div>
    </div>
  )
}

export const ReorderButton = ({ className, children }) => {
  const context = useContext(ReorderContext)

  return (
    // eslint-disable-next-line react/jsx-handler-names
    <Button className={classNames('reorder__button', className, { 'reorder__button--hidden': context.isReordering })} onClick={context.startReordering}>
      {children}
    </Button>
  )
}

export const ReorderDoneButton = ({ className, children }) => {
  const context = useContext(ReorderContext)

  const handleReorder = async () => {
    if (typeof context.onReorder !== 'function') return

    const order = [...context.order]
    await context.onReorder(order)

    context.setReordering(false)
  }

  const disabled = typeof context.onReorder !== 'function'

  return (
    <Button className={classNames('reorder__done-button', className, { 'reorder__button--hidden': !context.isReordering })} disabled={disabled} onClick={handleReorder}>
      {children}
    </Button>
  )
}

export const ReorderCancelButton = ({ className, children }) => {
  const context = useContext(ReorderContext)

  return (
    // eslint-disable-next-line react/jsx-handler-names
    <Button className={classNames('reorder__cancel-button', className, { 'reorder__button--hidden': !context.isReordering })} onClick={() => context.setReordering(false)}>
      {children}
    </Button>
  )
}

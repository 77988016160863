import { autorun, makeAutoObservable } from 'mobx'
import exposeDebug from '../utils/expose-debug'

const loginScreen = makeAutoObservable({
  uid: null,
  parseLocation () {
    const hash = window.location.hash
    const uid = (hash.match(/^#uid:([a-z0-9_-]{30})$/i) || [])[1]

    if (!uid) return

    this.uid = uid

    const linkWithoutHash = new window.URL(window.location)
    linkWithoutHash.hash = ''
    window.history.replaceState(null, '', linkWithoutHash.href)
  },
  restore () {
    if (this.hasUid || !window.localStorage) return

    const loadedUid = window.localStorage.getItem('spaceship-uid')
    if (!loadedUid) return

    this.uid = loadedUid
  },
  forget () {
    if (window.localStorage) window.localStorage.removeItem('spaceship-uid')
    this.uid = null
  },
  get hasUid () { return !!this.uid },
  setUid (uid) { this.uid = uid }
}, null, { autoBind: true })

setInterval(loginScreen.parseLocation, 10)

autorun(() => {
  if (!window.localStorage || !loginScreen.hasUid) return

  window.localStorage.setItem('spaceship-uid', loginScreen.uid)
})

loginScreen.restore()

exposeDebug('observables:loginScreen', loginScreen)

export default loginScreen

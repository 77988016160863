import { observer } from 'mobx-react-lite'
import { Route, Switch } from 'react-router-dom'

import user from './observables/user'

import LoginScreen from './components/LoginScreen'
import AlbumScreen from './components/AlbumScreen'
import GalleryScreen from './components/GalleryScreen'
import UploadScreen from './components/UploadScreen'
import PassengersScreen from './components/PassengersScreen'
// import AppScreen from './components/AppScreen'

const App = observer(() => {
  if (!user.loggedIn) return <LoginScreen />

  return (
    <Switch>
      <Route path='/pic/:pic'>
        <AlbumScreen />
      </Route>
      <Route exact path='/gallery'>
        <GalleryScreen />
      </Route>
      <Route exact path='/upload'>
        <UploadScreen />
      </Route>
      <Route exact path='/passengers'>
        <PassengersScreen />
      </Route>
      <Route path='*'>
        <AlbumScreen />
      </Route>
    </Switch>
  )
})

export default App

import { useState } from 'react'
import classNames from 'classnames'

import IconText from './IconText'

const defaultState = {
  active: false,
  title: '',
  message: '',
  responses: [],
  callback: null,
  options: {}
}

const defaultResponse = {
  text: 'OK',
  className: '',
  response: 'ok',
  isCancel: true
}

const useModal = () => {
  const [setup, setSetup] = useState(defaultState)
  const reset = () => setSetup(defaultState)

  const showModal = (title = '', message = '', responses = [defaultResponse], options = {}) => new Promise((resolve, reject) => {
    setSetup({
      active: true,
      title,
      message,
      responses,
      callback: (response) => () => {
        reset()
        resolve(response)
      },
      options
    })
  })

  const cancelResponse = (setup.responses.filter(response => response.isCancel)[0] || { response: null }).response

  const modal = (
    <>
      {
        !setup.active
          ? undefined
          : (
            <div className='modal is-active' onClick={(ev) => { ev.preventDefault(); ev.stopPropagation() }} style={{ cursor: 'auto' }}>
              <div className='modal-background' onClick={setup.callback(cancelResponse)} />
              <div className='modal-content'>
                <div className='card'>
                  <div className='use-modal__header'>
                    <div className='use-modal__title'>{setup.title}</div>
                  </div>
                  <div className='use-modal__body'>
                    {setup.message}
                  </div>
                  <div className='use-modal__buttons'>
                    {
                    setup.responses.map((response, index) => {
                      if (response.url) {
                        return (
                          <a
                            className={classNames('button use-modal__button', response.className)}
                            href={response.url}
                            target='_blank'
                            rel='noopener noreferrer'
                            onClick={setup.callback(response.response)}
                            key={index}
                          >
                            {response.icon ? <IconText icon={response.icon}>{response.text}</IconText> : response.text}
                          </a>
                        )
                      }

                      return (
                        <button
                          className={classNames('button use-modal__button', response.className)}
                          onClick={setup.callback(response.response)}
                          key={index}
                        >
                          {response.icon ? <IconText icon={response.icon}>{response.text}</IconText> : response.text}
                        </button>
                      )
                    })
                  }
                  </div>
                </div>
              </div>
            </div>
            )
      }
    </>
  )

  return [modal, showModal]
}

export default useModal

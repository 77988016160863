import { faTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import screenSize from '../../observables/screen-size'
import CommentSection from '../CommentSection'

import Favorites from '../Favorites'
import ActualImage from './ActualImage'
import ImageTitle from '../ImageTitle'

const MobileImage = observer(({ pic }) => {
  const [commentsOpen, setCommentsOpen] = useState(false)

  const mobileHorizontalSpace = screenSize.width
  const mobileVerticalSpace = screenSize.height - (screenSize.width <= 768 ? 11 : 14.5) * screenSize.fontSize

  const width = Math.min(mobileHorizontalSpace, mobileVerticalSpace * pic.aspectRatio)
  const height = width / pic.aspectRatio

  const [swipeDelta, setSwipeDelta] = useState(0)
  const [disregardSwipe, setDisregardSwipe] = useState(false)

  const swipeHandlers = useSwipeable({
    onSwipeStart: (event) => {
      const commentSection = event.event.composedPath().filter(element => element.classList && element.classList.contains('comment-section__comments'))[0]
      if (!commentSection) return

      const commentSectionY = commentSection.getBoundingClientRect().y
      const firstCommentY = commentSection.lastChild.getBoundingClientRect().y

      if (event.dir === 'Down' && Math.abs(commentSectionY - firstCommentY) > screenSize.fontSize * 2 + 1) {
        setDisregardSwipe(true)
      }
    },
    onSwiping: (event) => {
      if (disregardSwipe) return
      setSwipeDelta(event.absY > event.absX ? event.deltaY : 0)
    },
    onSwiped: (event) => {
      setSwipeDelta(0)
      setDisregardSwipe(false)
      if (disregardSwipe) return

      const friction = 1
      const finalDelta = Math.abs(event.deltaY + event.vxvy[1] * event.vxvy[1] / 2 / friction)
      if (finalDelta > screenSize.fontSize) {
        if (event.dir === 'Up') setCommentsOpen(true)
        if (event.dir === 'Down') setCommentsOpen(false)
      }
    }
  })

  const swipeSpace = screenSize.height - 13 * screenSize.fontSize
  const swipeMin = commentsOpen ? 0 : -swipeSpace
  const swipeMax = commentsOpen ? swipeSpace : 0

  const displayedSwipe = Math.min(Math.max(swipeDelta, swipeMin), swipeMax)

  return (
    <div
      className={classNames('mobile-image', {
        'mobile-image--comments-open': commentsOpen,
        'mobile-image--swiping': swipeDelta && !disregardSwipe
      })}
      {...swipeHandlers}
    >
      <div className='mobile-image__first-page' style={{ transform: `translateY(${displayedSwipe}px)` }}>
        <div className='mobile-image__image-area'>
          <ActualImage width={width} height={height} pic={pic} />
        </div>
        <div className='mobile-image__title'>
          <ImageTitle
            imageId={pic.iid}
            imageAuthor={pic.postedby}
            currentTitle={pic.title}
            userCanDelete={pic.cdi}
            isMobile
          />
        </div>
        <Favorites className='mobile-image__favorites' pic={pic} showComments darkMode onClick={() => setCommentsOpen(true)} />
        <div className='mobile-image__comment-prompt' onClick={() => setCommentsOpen(true)}>
          <FontAwesomeIcon icon={faTriangle} transform={{ rotate: 180 }} />
          <span className='mx-2'>SWIPE</span>
          <FontAwesomeIcon icon={faTriangle} transform={{ rotate: 180 }} />
        </div>
      </div>
      <div className='mobile-image__second-page' style={{ transform: `translateY(${displayedSwipe}px)` }}>
        <div className='mobile-image__comment-prompt' onClick={() => setCommentsOpen(false)}>
          <FontAwesomeIcon icon={faTriangle} />
          <span className='mx-2'>BACK TO IMAGE</span>
          <FontAwesomeIcon icon={faTriangle} />
        </div>
        <div className='level is-mobile my-1'>
          <div className='level-left mobile-image__author'>
            By {pic.postedby}
          </div>
          <div className='level-right mobile-image__date'>
            {new Date(pic.postedon).toLocaleDateString('en-us', { day: 'numeric', month: 'long', year: 'numeric', weekday: 'long' })}
          </div>
        </div>
        <CommentSection pic={pic} darkMode noPadding />
      </div>
    </div>
  )
})

export default MobileImage

import { makeAutoObservable } from 'mobx'
import exposeDebug from '../utils/expose-debug'

const screenSize = makeAutoObservable({
  width: 0,
  height: 0,
  fontSize: 0,
  scrollY: 0,
  get heroWidth () { return this.width - this.fontSize * (this.isMobile ? 3 : 6) },
  get heroHeight () { return this.height - this.fontSize * 8 },
  get isMobile () { return this.width <= this.fontSize * 36 },
  get aspectRatio () { return this.width / this.height },
  update () {
    this.width = window.innerWidth
    this.height = window.innerHeight
    this.fontSize = parseInt(window.getComputedStyle(document.documentElement).fontSize)
    this.scrollY = window.scrollY
  }
})

const loop = () => {
  window.requestAnimationFrame(loop)
  screenSize.update()

  // if (window.scrollY) window.scroll({ top: 0, behavior: 'auto' })
}

loop()

exposeDebug('observables:screenSize', screenSize)

export default screenSize

import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import screenSize from '../../observables/screen-size'

import CommentSection from '../CommentSection'
import Favorites from '../Favorites'
import ActualImage from './ActualImage'
import ImageTitle from '../ImageTitle'

const TallImage = observer(({ pic }) => {
  const tallHorizontalSpace = screenSize.width - 37 * screenSize.fontSize
  const tallVerticalSpace = screenSize.height - 8.5 * screenSize.fontSize

  const width = Math.min(tallHorizontalSpace, tallVerticalSpace * pic.aspectRatio)
  const height = width / pic.aspectRatio

  return (
    <div className={classNames('tall-image', {
      'tall-image--left': pic.side === 'left',
      'tall-image--right': pic.side === 'right'
    })}
    >
      <div className='tall-image__image-area'>
        <ActualImage width={width} height={height} pic={pic} />
      </div>
      <div className='tall-image__comment-section'>
        <div className='tall-image__title'>
          <ImageTitle
            imageId={pic.iid}
            imageAuthor={pic.postedby}
            currentTitle={pic.title}
            userCanDelete={pic.cdi}
          />
        </div>
        <div className='tall-image__author-row'>
          <div className='tall-image__author'>{pic.postedby}</div>
          <div className='tall-image__date'>
            {new Date(pic.postedon).toLocaleDateString('en-us', { day: 'numeric', month: 'long', year: 'numeric', weekday: 'long' })}
          </div>
        </div>
        <Favorites pic={pic} showText className='mb-4' darkMode />
        <CommentSection pic={pic} darkMode noPadding />
      </div>
    </div>
  )
})

export default TallImage

import { faCircleNotch, faUfo, faUserSecret } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import * as yup from 'yup'

import loginScreen from '../observables/login-screen'
import user from '../observables/user'

import logo from '../images/Logo-Full-Horz-Grey-96.png'
import Button from './Button'
import IconText from './IconText'
import Footer from './Footer'

const emailSchema = yup.string().email()

const LoginScreen = observer(() => {
  const [email, setEmail] = useState('')
  const buttonRef = useRef()

  const setEmailWithoutSpaces = (email) => setEmail(email.replace(/ /g, ''))

  const handleSubmit = async () => {
    if (!(await emailSchema.isValid(email))) throw new Error(`'${email}' is not a valid email address`)
    await user.login(email, loginScreen.uid)
  }

  const handleKeypress = (event) => {
    if (event.key === 'Enter') buttonRef.current.click()
  }

  const errorMap = {
    err2: 'Login failed', // nice try (uid is not 30 chars, which should never happen)
    err3: 'Access to this album is no longer available',
    err4: 'This album is no longer available',
    err5: 'Login failed, use the email address at which you received your invitation'
  }

  if (user.loading) {
    return (
      <div className='hero is-fullheight'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <FontAwesomeIcon icon={faCircleNotch} size='5x' spin />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='hero is-fullheight'>
      <div className='login-screen__body hero-body'>
        <div className='login-screen__container'>
          <div className='columns is-multiline is-centered is-mobile'>
            <div className='column is-full has-text-centered'>
              <div className='login-screen__main-logo has-text-centered-mobile'>
                <FontAwesomeIcon icon={faUfo} size='6x' />
                <div className='login-screen__title is-block-mobile is-inline-block-tablet ml-4 is-size-1 is-size-3-mobile has-text-weight-bold has-line-height-5'>
                  Photo Spaceship
                  <div className='login-screen__subtitle is-size-6 has-text-left has-text-centered-mobile has-line-height-5'>
                    by <img className='login-screen__logo' src={logo} alt='Bottlle™' style={{ height: '1.5em', verticalAlign: 'middle' }} />
                  </div>
                </div>
              </div>
            </div>
            <div className='column is-5-tablet is-8-mobile'>
              <div className='login-screen__message has-text-centered is-size-5 has-line-height-5'>
                Photo Spaceship is a shared photo album available&nbsp;through the&nbsp;Bottlle&nbsp;app.
              </div>
            </div>
            <div className='column is-full has-text-centered'>
              {
                loginScreen.hasUid
                  ? (
                    <div className='mt-5'>
                      <div className='field'>
                        <div className='field has-addons has-addons-centered'>
                          <div className='control login-screen__email pt-5 mt-2'>
                            <input
                              type='email'
                              className='input is-size-5'
                              value={email}
                              onChange={event => setEmailWithoutSpaces(event.target.value)}
                              placeholder='visitor@bottlle.com'
                              onKeyPress={handleKeypress}
                            />
                          </div>
                          <div className='control pt-5 mt-2'>
                            <Button
                              disabled={!email}
                              onClick={handleSubmit}
                              buttonRef={buttonRef}
                              errorMap={errorMap}
                              className='is-size-5'
                            >
                              Log in
                            </Button>
                          </div>
                        </div>
                        <div className='mt-6'>
                          <button className='login-screen__forget button is-size-5' onClick={() => loginScreen.forget()}>
                            <IconText icon={faUserSecret}>Forget Me</IconText>
                          </button>
                          <p className='help'>Click this button after logout<br />if using public computer</p>
                        </div>
                      </div>
                    </div>
                    )
                  : undefined
              }
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
})

export default LoginScreen

import { runInAction } from 'mobx'

const debugContainer = {}
window.debug = debugContainer

const exposeDebug = (name, object) => {
  if (window.localStorage && String(window.localStorage.getItem('spaceship-debug')).split(' ').includes(name)) {
    const path = name.split(/[.:]/).filter(s => s)
    const container = path.slice(0, -1).reduce((acc, key) => {
      if (acc[key] == null) acc[key] = {}
      return acc[key]
    }, debugContainer)
    container[path.slice(-1)[0]] = object
  }
}

debugContainer.setup = (value) => {
  if (!window.localStorage) throw new Error('cannot initialize debugger: localStorage unavailable')

  window.localStorage.setItem('spaceship-debug', value)
  window.location.reload()
}

debugContainer.reset = () => debugContainer.setup('')

debugContainer.runInAction = runInAction

export default exposeDebug

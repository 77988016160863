import classNames from 'classnames'
import MurmurHash3 from 'imurmurhash'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import album from '../observables/album'

import screenSize from '../observables/screen-size'
import Favorites from './Favorites'

const randomValue = (name, url) => MurmurHash3(name).hash(url.split('').reverse().join('')).result() / 0xFFFFFFFF

const GalleryImage = observer(({ pic, className, index }) => {
  const maxDimension = screenSize.fontSize * 9
  const width = pic.aspectRatio < 1 ? maxDimension * pic.aspectRatio : maxDimension
  const height = width / pic.aspectRatio
  const vertical = pic.aspectRatio < (album.funModeEnabled ? 0.99 : 0.51)

  const borderWidth = width + (vertical ? 3 : 1) * screenSize.fontSize
  const borderHeight = height + (vertical ? 1 : 3) * screenSize.fontSize

  const [isHover, setHover] = useState(false)
  const callback = useCallback(element => {
    if (!element) return

    const handler = (event) => {
      setHover(event.type === 'mouseenter')
    }

    element.addEventListener('mouseenter', handler)
    element.addEventListener('mouseleave', handler)

    return () => {
      element.removeEventListener('mouseenter', handler)
      element.removeEventListener('mouseleave', handler)
    }
  }, [])

  const initialRotation = randomValue('initial', pic.url) * 6 - 3
  const direction = randomValue('direction', pic.url) > 0.5
  const hoverRotation = initialRotation + (randomValue('hover', pic.url) * 3 + 3) * (direction ? 1 : -1)

  const initialTransform = album.funModeEnabled ? `rotate(${initialRotation}deg)` : ''
  const hoverTransform = album.funModeEnabled ? `rotate(${hoverRotation}deg) scale(1.05)` : 'scale(1.05)'
  const imageTransform = isHover ? hoverTransform : initialTransform

  const ref = useRef()
  const [imageVisible, setImageVisible] = useState(false)

  useEffect(() => {
    let lööping = true

    const lööp = () => {
      if (!lööping) return
      window.requestAnimationFrame(lööp)

      if (!ref.current) return
      const rect = ref.current.getBoundingClientRect()

      const shouldBeVisible = rect.top < window.innerHeight * 3 && rect.bottom > -window.innerHeight * 2
      if (shouldBeVisible !== imageVisible) setImageVisible(shouldBeVisible)
    }

    lööp()

    return () => (lööping = false)
  }, [imageVisible])

  return (
    <div className={classNames('gallery-image', className)} ref={ref}>
      <div className='gallery-image__instant-container'>
        <Link
          className={classNames('gallery-image__instant-border', {
            'gallery-image__instant-border--vertical': vertical,
            'gallery-image__instant-border--serious': !album.funModeEnabled
          })}
          style={{ width: `${borderWidth}px`, height: `${borderHeight}px`, transform: imageTransform }}
          ref={callback}
          to={`/pic/${index}`}
        >
          <img className='gallery-image__actual' src={imageVisible ? pic.thumbnailUrl : album.placeholderUrl} alt={pic.title} width={width} height={height} />
          <Favorites className='gallery-image__favorites' pic={pic} maxHearts={3} showComments vertical={vertical} />
        </Link>
      </div>
      <div className='gallery-image__label-container'>
        <div className='gallery-image__title'>{pic.title}</div>
        <div className='gallery-image__author'>{pic.postedby}</div>
        <div className='gallery-image__date'>
          {new Date(pic.postedon).toLocaleDateString('en-us', { day: 'numeric', month: 'long', year: 'numeric', weekday: 'long' })}
        </div>
      </div>
    </div>
  )
})

export default GalleryImage

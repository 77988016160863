import MurmurHash3 from 'imurmurhash'
import { autorun, makeAutoObservable, runInAction } from 'mobx'
import exposeDebug from '../utils/expose-debug'
import apiCall from '../utils/api-call'

import user from './user'

const album = makeAutoObservable({
  data: null,
  loading: false,
  picId: 0,
  placeholderUrl: '',
  get funModeEnabled () { return this.data.albumheader.style === 'fun' },
  get commentsEnabled () { return !!this.data.albumheader.commenting },
  get pics () {
    if (!this.data) return []

    const albumheader = this.data.albumheader

    return this.data.albumimages.map((image, index) => {
      const url = new URL(image.image, albumheader.imgdir).href
      const thumbnailUrl = new URL(image.image, albumheader.thumbdir || albumheader.imgdir).href

      const width = image.w || 0
      const height = image.h || 0

      const aspectRatio = (width && height) ? width / height : 1

      const side = (MurmurHash3(url.split('').reverse().join('')).result() % 2) ? 'left' : 'right'

      return {
        ...image,
        comments: image.comments.map(comment => {
          return {
            ...comment,
            ppicUrl: new URL(comment.ppic, albumheader.ppicdir).href
          }
        }),
        url,
        thumbnailUrl,
        width,
        height,
        aspectRatio,
        side,
        index
      }
    })
  },
  get currentPic () { return this.pics[this.picId] || null },
  get isEndCard () { return this.picId === this.pics.length },
  forward () { this.picId = Math.min(this.picId + 1, this.pics.length) },
  backward () { this.picId = Math.max(this.picId - 1, 0) },
  go (picId) { this.picId = picId % (this.pics.length + 1) },
  * load (softReload) {
    this.loading = softReload ? this.loading : true

    const result = yield apiCall('WEBPSS.ALBUM.GT.W1', { sid: user.sid })

    this.data = result[0] || null
    this.loading = false
  },
  * loadSingle (imageId, softReload) {
    this.loading = softReload ? this.loading : true

    const result = yield apiCall('WEBPSS.IMAGE.GT.W1', { sid: user.sid, iid: imageId })

    const index = this.data.albumimages.map(image => image.iid).indexOf(imageId)

    this.data.albumimages[index] = result[0]

    this.loading = false
  },
  unload () {
    this.data = null
  },
  * deleteImage (imageId) {
    yield apiCall('WEBPSS.IMAGE.DL.W1', {
      sid: user.sid,
      iid: imageId
    })

    yield album.load(false)
  },
  * addComment (imageId, text) {
    yield apiCall('WEBPSS.CMT.PS.W1', {
      sid: user.sid,
      iid: imageId,
      txt: text,
      fav: 0
    })

    yield album.loadSingle(imageId, true)
  },
  * editComment (commentId, text, imageId) {
    yield apiCall('WEBPSS.CMT.PT.W1', {
      sid: user.sid,
      cid: commentId,
      txt: text
    })

    yield album.loadSingle(imageId, true)
  },
  * deleteComment (commentId, imageId) {
    yield apiCall('WEBPSS.CMT.DL.W1', {
      sid: user.sid,
      icid: commentId,
      rm: 2
    })

    yield album.loadSingle(imageId, true)
  },
  * favorImage (imageId) {
    yield apiCall('WEBPSS.CMT.PS.W1', {
      sid: user.sid,
      iid: imageId,
      txt: '#',
      fav: 1
    })

    yield album.loadSingle(imageId, true)
  },
  * unFavorImage (imageId) {
    yield apiCall('WEBPSS.CMT.DL.W1', {
      sid: user.sid,
      icid: imageId,
      rm: 1
    })

    yield album.loadSingle(imageId, true)
  },
  * addImageTitle (imageId, text) {
    yield apiCall('WEBPSS.TITLE.PS.W1', {
      sid: user.sid,
      iid: imageId,
      txt: text
    })

    yield album.loadSingle(imageId, true)
  },
  * deleteImageTitle (imageId) {
    yield apiCall('WEBPSS.TITLE.DL.W1', {
      sid: user.sid,
      iid: imageId
    })

    yield album.loadSingle(imageId, true)
  },
  * offensiveContentReport (imageId, contentId) {
    yield apiCall('WEBPSS.REPORTCX.PS.W1', {
      sid: user.sid,
      icid: contentId
    })

    yield album.loadSingle(imageId, true)
  }
}, null, { autoBind: true })

window.addEventListener('keydown', (event) => {
  if (event.key === 'ArrowLeft') album.backward()
  if (event.key === 'ArrowRight') album.forward()
})

autorun(() => {
  if (!user.loggedIn) album.unload()

  if (user.uid) album.load() // explicitly read user.uid so that when it changes, we reload the album
})

let lastSeenUrlParam = -1

autorun(() => {
  if (!user.loggedIn || album.loading) return

  const picId = album.picId // in case checkRestore returns true album.picId is not recorded in autorun and this never runs again, so we have to retrieve it here
  if (checkRestore()) return

  const path = window.location.pathname
  if (path === `/pic/${picId + 1}`) return
  if (path === '/' || path.startsWith('/pic/')) {
    window.history.replaceState(null, null, `/pic/${picId + 1}`)
    lastSeenUrlParam = picId + 1
  }
})

const checkRestore = () => {
  if (!user.loggedIn || album.loading) return false

  const path = window.location.pathname
  const picId = (path.match(/^\/pic\/(\d+)$/) || [])[1] || null

  if (!picId) return false
  if (picId === lastSeenUrlParam) return false

  lastSeenUrlParam = picId
  album.go(picId - 1)
  return true
}

const loop = () => {
  window.requestAnimationFrame(loop)
  checkRestore()
}

loop()

const createPlaceholder = async () => {
  const canvas = document.createElement('canvas')
  canvas.width = 480
  canvas.height = 480

  const blob = await new Promise((resolve, reject) => canvas.toBlob(blob => resolve(blob)))

  const url = URL.createObjectURL(blob)

  runInAction(() => { album.placeholderUrl = url })
}

createPlaceholder()

exposeDebug('observables:album', album)

export default album

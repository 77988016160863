import { autorun, makeAutoObservable } from 'mobx'
import exposeDebug from '../utils/expose-debug'
import apiCall from '../utils/api-call'

import user from './user'
import album from './album'

const commentsAreOnPermissionsMap = {
  1: 'Owner',
  2: 'Admin',
  3: 'View, Upload & Comment',
  4: 'View & Comment',
  5: 'View Only'
}

const commentsAreOffPermissionsMap = {
  1: 'Owner',
  2: 'Admin',
  3: 'View & Upload',
  4: 'View'
}

const spaceshipUsers = makeAutoObservable({
  data: null,
  loading: false,
  addFormOpen: false,
  get users () {
    if (!this.data) return []
    return this.data.map(({ f, l, ppic, p, aid, self }) => {
      return {
        name: `${f} ${l}`.length <= 40 ? `${f} ${l}` : `${f.split(' ')[0]} ${l[0]}.`,
        avatar: `https://as3img.bottlle.com/user/${ppic}`,
        level: Number(p),
        levelDesc: this.permissionsMap[p],
        aid,
        self
      }
    })
  },
  get permissionsMap () { return album.commentsEnabled ? commentsAreOnPermissionsMap : commentsAreOffPermissionsMap },
  setAddFormState () {
    this.addFormOpen = !this.addFormOpen
  },
  * load () {
    this.loading = true

    const result = yield apiCall('WEBPSS.USER.GT.W1', { sid: user.sid })

    this.data = result || null

    this.loading = false
  },
  * addUser (f, l, e, p) {
    const lastName = l === '' ? ':n:i:l::::::::::::::::::::::::::::::::::' : l
    yield apiCall('WEBPSS.USER.PS.W1', {
      sid: user.sid,
      f,
      l: lastName,
      e,
      p
    })

    yield spaceshipUsers.load()
  },
  * deleteUser (aid) {
    yield apiCall('WEBPSS.USER.DL.W1', {
      sid: user.sid,
      aid
    })

    yield spaceshipUsers.load()
  },
  * changeUserPermissionLevel (aid, p) {
    yield apiCall('WEBPSS.PERM.PT.W1', {
      sid: user.sid,
      aid,
      p
    })

    yield spaceshipUsers.load()
  },
  * resendInvitationEmail (aid) {
    yield apiCall('WEBPSS.EMAIL.PS.W1', {
      sid: user.sid,
      aid
    })

    yield spaceshipUsers.load()
  }
})

autorun(() => {
  if (user.loggedIn && user.isAdmin) spaceshipUsers.load()
})

exposeDebug('observables:spaceship-users', spaceshipUsers)

export default spaceshipUsers

import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import album from '../observables/album'
import screenSize from '../observables/screen-size'

const Sprockets = observer(() => {
  const isMobile = (screenSize.width <= 768)
  const spacing = isMobile ? 3 : 5
  const current = album.picId

  return (
    <div className='sprockets'>
      <div className='sprockets__left'>
        <button className='button is-dark sprockets__side-button' onClick={() => album.go(0)}>{isMobile ? 'S' : 'START'}</button>
      </div>
      <div className='sprockets__actual'>
        {
          new Array(21).fill().map((_value, index) => {
            const number = current + 1 + index - 10
            if (number <= 0) return null
            if (number > album.pics.length + 1) return null

            const text = (number === album.pics.length + 1) ? 'END' : ((!(number % spacing) || number === 1) ? number : '•')

            return (
              <button
                className='button is-dark sprockets__sprocket'
                key={`sprocket-${number}`}
                style={{ transform: `translate(${(index - 10) * 3}em)` }}
                onClick={() => album.go(number - 1)}
              >
                {text}
              </button>
            )
          })
        }
      </div>
      <div className='sprockets__right'>
        <button className='button is-dark sprockets__side-button' onClick={() => album.go(album.pics.length)}>{isMobile ? 'E' : 'END'}</button>
      </div>
      <div className='sprockets__carets'>
        <div className='sprockets__caret-top'>
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
        <div className='sprockets__caret-bottom'>
          <FontAwesomeIcon icon={faCaretUp} />
        </div>
      </div>
    </div>
  )
})

export default Sprockets

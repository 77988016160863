import { makeAutoObservable } from 'mobx'
import { v4 as uuid } from 'uuid'
import album from './album'

const imageStore = {}

const imageUploads = makeAutoObservable({
  images: {},
  nextSequence: 1,
  uploading: false,
  get sortedImages () {
    return Object.values(this.images).sort((a, b) => a.sequence - b.sequence)
  },
  get progress () {
    return this.sortedImages.map(image => image.uploadRatio).reduce((a, b) => a + b, 0) / (this.sortedImages.length || 1)
  },
  registerImage (name, originalType) {
    const image = {
      id: uuid(),
      name,
      sequence: this.nextSequence++,
      originalType,
      type: null,
      url: null,
      dataPresent: false,
      width: null,
      height: null,
      dimensionsPresent: false,
      aspectRatio: 1,
      uploadState: 'idle', // waiting, uploading, done, error
      uploadRatio: 0
    }
    this.images[image.id] = image

    return image.id
  },
  registerDimensions (id, width, height) {
    if (!this.images[id]) throw new Error(`image with registration ID ${id} not found`)

    this.images[id] = {
      ...this.images[id],
      width,
      height,
      dimensionsPresent: true,
      aspectRatio: width / height
    }
  },
  registerData (id, data, type) {
    if (!this.images[id]) throw new Error(`image with registration ID ${id} not found`)

    imageStore[id] = data

    this.images[id] = {
      ...this.images[id],
      type,
      url: window.URL.createObjectURL(data),
      dataPresent: true
    }
  },
  deleteImage (id) {
    if (!this.images[id]) throw new Error(`image with registration ID ${id} not found`)

    delete this.images[id]
    delete imageStore[id]
  },
  reorderImages (newOrder) {
    const sortedImages = [...this.sortedImages]
    for (let i = 0; i < sortedImages.length; i++) {
      this.images[sortedImages[newOrder[i]].id].sequence = i
    }
  },
  updateUpload (id, state, ratio) {
    if (!this.images[id]) throw new Error(`image with registration ID ${id} not found`)

    console.log('update upload', id, state, ratio)

    this.images[id] = {
      ...this.images[id],
      uploadState: state,
      uploadRatio: ratio
    }
  },
  startUpload () {
    this.uploading = true
  },
  * finishUpload () {
    this.images = {}
    for (const key in imageStore) delete imageStore[key]

    this.uploading = false

    yield album.load(true)
  }
}, null, { autoBind: true })

Object.assign(imageUploads, {
  get store () { return imageStore }
})

export default imageUploads

import { useState } from 'react'
import unwrap from 'async-unwrap'
import classNames from 'classnames'

import useModal from './useModal'
import { APIError } from '../utils/api-call'
import { faCheck } from '@fortawesome/pro-light-svg-icons'

const Button = props => {
  const [isLoading, setLoading] = useState(false)
  const [modal, showModal] = useModal()

  const errorMap = {
    err1: 'An error occured while submitting your request (e1)',
    ...(props.errorMap || {})
  }

  const handleOnClick = async (event) => {
    setLoading(true)

    const [error] = await Promise.resolve(props.onClick(event))[unwrap]
    if (error) {
      const errorMsg = (error instanceof APIError && errorMap[error.code]) ||
        (error ? (error.message || error) : 'Unknown Error')
      await showModal('Error', errorMsg, [{ text: 'OK', className: 'is-dark', response: 'ok', icon: faCheck }])
    }

    setLoading(false)
  }

  const sanitizedProps = { ...props }
  delete sanitizedProps.buttonRef
  delete sanitizedProps.onClick
  delete sanitizedProps.errorMap

  return (
    <>
      <button
        {...sanitizedProps}
        ref={props.buttonRef}
        type='button'
        disabled={props.disabled || isLoading}
        className={classNames(props.className, 'button', { 'is-loading': isLoading })}
        onClick={props.onClick ? handleOnClick : null}
      />
      {modal}
    </>
  )
}

export default Button

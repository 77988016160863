import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
// import reportWebVitals from './reportWebVitals'

import './sass/index.sass'

const rootElement = document.getElementById('root')

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  rootElement
)

// handle drag and drop globally to avoid having the page replaced with random documents
rootElement.addEventListener('dragover', event => event.preventDefault())
rootElement.addEventListener('drop', event => event.preventDefault())

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

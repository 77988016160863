import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons'
import * as yup from 'yup'

import spaceshipUsers from '../observables/spaceship-users'
import album from '../observables/album'
import user from '../observables/user'

const AddPassengerForm = observer(() => {
  const [isLoading, setLoading] = useState(false)
  const permissionsAvailable = album.loading ? {} : spaceshipUsers.permissionsMap
  const permissionOpts = Object.keys(permissionsAvailable).map(permissionKey => {
    if (permissionKey === '1') return null
    if (permissionKey === '2' && user.permissionLevel !== 1) return null
    return (<option key={permissionKey} value={permissionKey}>{permissionsAvailable[permissionKey]}</option>)
  })

  const newPassengerSchema = yup.object().shape({
    f: yup.string().required('Required'),
    l: yup.string().when('p', { is: '2', then: yup.string().required('Required'), otherwise: yup.string() }),
    e: yup.string().email('Invalid email address').required('Required'),
    p: yup.mixed().required('Required').oneOf(Object.keys(permissionsAvailable), 'Select a valid permission')
  })

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(newPassengerSchema)
  })

  const formSubmit = async data => {
    setLoading(true)
    const { f, l, e, p } = data
    await spaceshipUsers.addUser(f, l, e, p)
    reset()
    setLoading(false)
    spaceshipUsers.setAddFormState()
  }

  const handleCancel = () => {
    spaceshipUsers.setAddFormState()
    reset()
  }

  return (
    <form onSubmit={handleSubmit(formSubmit)} className={classNames('add-passenger-form', { activated: spaceshipUsers.addFormOpen })}>

      <div className='field'>
        <label htmlFor='f' className='label has-text-white'>First Name</label>
        <div className='control'>
          <input className='input' type='text' {...register('f')} placeholder='First Name' />
        </div>
        <p className='help is-warning'>{errors.f?.message}</p>
      </div>

      <div className='field'>
        <label htmlFor='l' className='label has-text-white'>Last Name</label>
        <div className='control'>
          <input className='input' type='text' {...register('l')} placeholder='Last Name' />
        </div>
        <p className='help is-warning'>{errors.l?.message}</p>
      </div>

      <div className='field'>
        <label htmlFor='e' className='label has-text-white'>Email Address</label>
        <div className='control'>
          <input className='input' type='email' {...register('e')} placeholder='Email Address' />
        </div>
        <p className='help is-warning'>{errors.e?.message}</p>
      </div>

      <div className='field'>
        <label htmlFor='p' className='label has-text-white'>Permission Level</label>
        <div className='control'>
          <div className='select'>
            <select {...register('p')}>
              <option key={6} value=''>Choose permission level</option>
              {permissionOpts}
            </select>
          </div>
        </div>
        <p className='help is-warning'>{errors.p?.message}</p>
      </div>

      <div className='field is-grouped'>
        <div className='control is-expanded has-standard-basis'>
          <button className='button is-outlined is-white is-fullwidth' type='button' onClick={handleCancel}>
            <span>Cancel</span>
            <span className='icon'><FontAwesomeIcon icon={faTimes} /></span>
          </button>
        </div>
        <div className='control is-expanded has-standard-basis'>
          <button className={classNames('button is-outlined is-white is-fullwidth', { 'is-loading': isLoading })} type='submit' onFocus={ev => ev.target.blur()}>
            <span>Add</span>
            <span className='icon'><FontAwesomeIcon icon={faCheck} /></span>
          </button>
        </div>
      </div>

    </form>
  )
})

export default AddPassengerForm

import { faCircleNotch } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import album from '../../observables/album'

const ActualImage = observer(({ pic, width, height }) => {
  const imageShouldRender = Math.abs(pic.index - album.picId) < 2

  const imageRef = useRef()
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    let isLooping = true

    const loop = () => {
      if (!isLooping) return
      window.requestAnimationFrame(loop)

      if (!imageRef.current) return
      if (Boolean(imageRef.current.complete) !== imageLoaded) setImageLoaded(Boolean(imageRef.current.complete))
    }

    loop()

    return () => (isLooping = false)
  }, [imageLoaded])

  const hideLoadingIcon = imageShouldRender && imageLoaded

  return (
    <div
      className={classNames('actual-image', {
        'actual-image--no-loading-icon': hideLoadingIcon
      })}
      style={{
        width: `${width}px`,
        height: `${height}px`
      }}
    >
      {
        !hideLoadingIcon
          ? (
            <div className='actual-image__loading-icon'>
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
            )
          : undefined
      }
      {
        imageShouldRender
          ? (
            <img className='actual-image__image' src={pic.url} ref={imageRef} /> // eslint-disable-line
            )
          : undefined
      }
    </div>
  )
})

export default ActualImage

import { observer } from 'mobx-react-lite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons'
import classNames from 'classnames'

import Button from './Button'
import { faCheck, faUndo, faBadgeSheriff, faTrashAlt, faEnvelope, faTimes } from '@fortawesome/pro-light-svg-icons'
import useModal from './useModal'

import spaceshipUsers from '../observables/spaceship-users'
import user from '../observables/user'

const SpaceshipUser = observer(({ passenger }) => {
  const [settingsMenuState, setSettingsMenu] = useState(false)
  const [permissionMenuState, setPermissionMenu] = useState(false)
  const [permissionLevel, setPermissionLevel] = useState(passenger.level)
  const [invitationFeedback, setInvitationFeedback] = useState((<><span>Resend Invitation</span><span className='icon is-small'><FontAwesomeIcon icon={faEnvelope} /></span></>))
  const [modal, showModal] = useModal()

  const permissionLevelOpts = Object.keys(spaceshipUsers.permissionsMap).map(permissionKey => {
    if (permissionKey === '1') return null
    if (permissionKey === '2' && user.permissionLevel !== 1) return null
    return (<option key={permissionKey} value={permissionKey}>{spaceshipUsers.permissionsMap[permissionKey]}</option>)
  })

  const deleteErrorMap = {
    err2: 'Your session has expired (e2)',
    err3: 'An error occured while attempting to remove user (e3)',
    err4: 'An error occured while attempting to remove user (e4)'
  }

  const resendInvitationErrorMap = {
    err2: 'Your session has expired (e2)',
    err3: 'An error occured while attempting to resend invitation (e3)',
    err4: 'An error occured while attempting to resend invitation (e4)',
    err5: 'An error occured while attempting to resend invitation (e5)'
  }

  const changePermissionErrorMap = {
    err2: 'Your session has expired (e2)',
    err3: 'An error occured while attempting to set permission level (e3)',
    err4: 'An error occured while attempting to set permission level (e4)',
    err5: 'An error occured while attempting to set permission level (e5)'
  }

  const handleDelete = async () => {
    const choice = await showModal(
      'Delete User',
      `Are you sure you'd like to remove ${passenger.name} from the list of users of this SpaceShip?`,
      [{
        text: 'No',
        response: 'no',
        className: 'modal-cancel',
        icon: faUndo,
        iconProps: {
          size: '2x'
        },
        isCancel: true
      }, {
        text: 'Yes',
        className: 'is-dark',
        response: 'yes',
        icon: faCheck,
        iconProps: {
          size: '2x'
        }
      }]
    )
    if (choice === 'no') return
    await spaceshipUsers.deleteUser(passenger.aid)
  }

  const handlePermissionChange = async () => {
    await spaceshipUsers.changeUserPermissionLevel(passenger.aid, permissionLevel)
    setPermissionMenu(false)
  }

  const timeOut = (time) => new Promise(resolve => setTimeout(resolve, time))

  const handleResendInvitation = async () => {
    await spaceshipUsers.resendInvitationEmail(passenger.aid)
    setInvitationFeedback(<><span>Success</span><span className='icon is-small'><FontAwesomeIcon icon={faCheck} /></span></>)
    await timeOut(1500)
    setInvitationFeedback(<><span>Resend Invitation</span><span className='icon is-small'><FontAwesomeIcon icon={faEnvelope} /></span></>)
    await timeOut(500)
    setSettingsMenu(false)
  }

  const handlePermissionLevelSelect = ev => {
    setPermissionLevel(Number(ev.target.value))
  }

  const handleOpenPermissionSelector = () => {
    setPermissionMenu(!permissionMenuState)
    setSettingsMenu(false)
  }

  const handleOpenUserMenu = () => {
    setSettingsMenu(!settingsMenuState)
    setPermissionMenu(false)
  }

  const deleteUserButton = (
    <Button
      className={classNames('is-outlined is-white is-borderless is-radiusless is-flex is-justify-content-space-between')}
      onClick={handleDelete}
      errorMap={deleteErrorMap}
    >
      <span>Remove</span>
      <span className='icon is-small'><FontAwesomeIcon icon={faTrashAlt} /></span>
    </Button>
  )

  const changePermissionLevelButton = (
    <button type='button' className='button is-outlined is-white is-borderless is-radiusless is-flex is-justify-content-space-between' onClick={handleOpenPermissionSelector}>
      <span>Change Permission Level</span>
      <span className='icon is-small'><FontAwesomeIcon icon={faBadgeSheriff} /></span>
    </button>
  )

  const resendInvitationButton = (
    <Button
      className={classNames('is-outlined is-white is-borderless is-radiusless is-flex is-justify-content-space-between resend-invitation-button')}
      onClick={handleResendInvitation}
      errorMap={resendInvitationErrorMap}
    >
      {invitationFeedback}
    </Button>
  )

  const changePermissionLevelControls = (
    <div className={classNames('content', { active: permissionMenuState })}>
      <div className='select spaceship-user__permission-selector is-fullwidth'>
        <select value={permissionLevel} onChange={handlePermissionLevelSelect}>
          {permissionLevelOpts}
        </select>
      </div>
      <div className={classNames('field is-grouped mt-3')}>
        <div className='control is-expanded has-standard-basis'>
          <button type='button' className='button is-outlined is-white is-fullwidth' onClick={handleOpenPermissionSelector}>
            <span>Cancel</span>
            <span className='icon is-small'><FontAwesomeIcon icon={faTimes} /></span>
          </button>
        </div>
        <div className='control is-expanded has-standard-basis'>
          <Button
            className={classNames('button is-outlined is-white is-fullwidth')}
            onClick={handlePermissionChange}
            errorMap={changePermissionErrorMap}
          >
            <span>Save</span>
            <span className='icon is-small'><FontAwesomeIcon icon={faCheck} /></span>
          </Button>
        </div>
      </div>
    </div>
  )

  return (
    <div className='spaceship-user card is-shadowless mb-3 has-text-white'>
      <div className='card-content'>
        <div className='media mb-0'>
          <div className='media-left'>
            <div className='image is-48x48'>
              <img className='is-rounded' src={passenger.avatar} alt={`${passenger.name} Avatar`} />
            </div>
          </div>
          <div className='media-content'>
            <p className='title is-5 mb-1 has-text-white'>{passenger.name}</p>
            <p className='is-size-6 has-text-white'>{passenger.levelDesc}</p>
          </div>
          {
            user.permissionLevel <= 2
              ? (
                <div className='media-right'>
                  <div className='spaceship-user__settings-trigger' onClick={handleOpenUserMenu}>
                    <span className='icon'><FontAwesomeIcon icon={faEllipsisV} /></span>
                  </div>
                  <div className={classNames('spaceship-user__settings-menu-items is-flex is-flex-direction-column', { open: settingsMenuState })}>
                    {user.permissionLevel < passenger.level || passenger.self ? (passenger.level === 1 ? null : deleteUserButton) : null}
                    {user.permissionLevel < passenger.level && passenger.level !== 1 ? changePermissionLevelButton : null}
                    {resendInvitationButton}
                  </div>
                </div>
                )
              : null
          }
        </div>
        {user.permissionLevel < passenger.level && passenger.level !== 1 ? changePermissionLevelControls : null}
      </div>
      {modal}
    </div>
  )
})

export default SpaceshipUser

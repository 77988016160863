export const apiUrl = 'https://as3api.bottlle.com'
export const apiKey = '5kh0Q&Kh$5=oBqpyoe1NJ_rUK$8Z(7-?s90'

export class APIError extends Error {
  name = 'API Error'
}

export const optimisticJsonParser = (text, ogText = null) => {
  try {
    return JSON.parse(text)
  } catch (err) {
    const newline = text.indexOf('\n')
    if (newline === -1) throw Error(`Cannot parse JSON: ${ogText || text}`)
    else return optimisticJsonParser(text.slice(newline + 1), ogText || text)
  }
}

const apiCall = async (pc, params) => {
  const result = await window.fetch(apiUrl, {
    method: 'post',
    body: JSON.stringify({
      ...params,
      pc: `${pc}.${apiKey}`
    })
  })

  const text = await result.text()
  const json = optimisticJsonParser(text)

  const requestOK = json?.h?.s === 'ok'
  if (!requestOK) {
    const error = new APIError(json?.h?.rt || 'Unknwon Error')
    error.code = json?.h?.rt
    throw error
  }

  return json.d
}

export default apiCall

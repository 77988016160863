import { faBookOpen, faFilmCanister, faImagePolaroid, faSignOut, faUpload, faUserCircle, faExchange, faGavel, faBalanceScaleRight, faUsers } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'

import user from '../observables/user'
import screenSize from '../observables/screen-size'
import Button from './Button'

import Dropdown from './Dropdown'
import IconText from './IconText'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import album from '../observables/album'
import { faUfo } from '@fortawesome/pro-regular-svg-icons'

import { detect } from 'detect-browser'

const browser = detect()
const blacklistedBrowsers = ['ios', 'safari', 'ios-browser']

const isBrowserBlacklisted = blacklistedBrowsers.includes(browser.name) || browser.os === 'iOS'

const currentYear = new Date().getFullYear()

const AppScreen = observer(({ children, bodyClassName, switchGalleryButton }) => {
  const aboveTextBreakpoint = screenSize.width / screenSize.fontSize > 70

  const location = useLocation()
  const isSpotlight = /^(?:\/|\/pic\/\d+)$/.test(location.pathname)
  const isGallery = location.pathname === '/gallery'
  const isUpload = location.pathname === '/upload'
  const isPassengers = location.pathname === '/passengers'

  const handleDownloadMagazine = async () => {
    const result = await window.fetch('http://localhost:8080/api/magazine', {
      method: 'post',
      body: JSON.stringify({ sid: user.sid }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const data = await result.json()

    console.log(data)
  }

  return (
    <div className='hero app-screen__hero' style={{ height: isBrowserBlacklisted ? '' : `${screenSize.height}px` }}>
      <div className='hero-head'>
        <div className='level is-mobile m-2'>
          <div className='level-left has-text-weight-bold app-screen__logo-icon'>
            <IconText collapseMobile icon={faUfo}>{aboveTextBreakpoint ? 'Photo Spaceship' : 'Spaceship'}</IconText>
          </div>
          <div className='level-right'>
            {
              screenSize.isMobile
                ? undefined
                : (
                  <>
                    {
                      !isSpotlight
                        ? (
                          <Link to='/' className='button is-white mr-2'>
                            <IconText collapseMobile icon={faImagePolaroid}>{aboveTextBreakpoint ? 'Spotlight View' : 'Spotlight'}</IconText>
                          </Link>
                          )
                        : undefined
                    }
                    {
                      !isGallery
                        ? (
                          <Link to='/gallery' className='button is-white mr-2'>
                            <IconText collapseMobile icon={faFilmCanister}>{aboveTextBreakpoint ? 'Gallery View' : 'Gallery'}</IconText>
                          </Link>
                          )
                        : undefined
                    }
                    {
                      user.canUpload && !isUpload
                        ? (
                          <Link to='/upload' className='button is-white mr-2'>
                            <IconText collapseMobile icon={faUpload}>{aboveTextBreakpoint ? 'Upload Photos' : 'Upload'}</IconText>
                          </Link>
                          )
                        : undefined
                    }
                  </>
                  )
            }

            <Dropdown
              className='is-right'
              menu={
                <>
                  {
                    !album.loading
                      ? (
                        <>
                          <div className='dropdown-item'>
                            <div className='has-text-weight-bold is-size-6'>{album.data.albumheader.albumname}</div>
                            <div className='is-size-7'>By {album.data.albumheader.ownerf} {album.data.albumheader.ownerl}</div>
                            <div className='is-size-7'>
                              {
                                album.funModeEnabled
                                  ? <>Spaceship disappears on<br /></>
                                  : 'Expires on '
                              }
                              {
                              new Date(album.data.albumheader.expires).toLocaleString('en-us', {
                                weekday: 'short',
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric'
                              })
                              }
                            </div>
                          </div>
                          {
                            screenSize.isMobile
                              ? <hr className='drowdown-divider my-1' />
                              : undefined
                          }
                        </>
                        )
                      : undefined
                  }
                  {
                    !isGallery && screenSize.isMobile
                      ? (
                        <Link to='/gallery' className='dropdown-item'>
                          <IconText icon={faFilmCanister}>Gallery View</IconText>
                        </Link>
                        )
                      : undefined
                  }
                  {
                    !isSpotlight && screenSize.isMobile
                      ? (
                        <Link to={`/pic/${album.picId + 1}`} className='dropdown-item'>
                          <IconText icon={faImagePolaroid}>Spotlight View</IconText>
                        </Link>
                        )
                      : undefined
                  }
                  {
                    !isUpload && user.canUpload && screenSize.isMobile
                      ? (
                        <Link to='/upload' className='dropdown-item'>
                          <IconText icon={faUpload}>Upload Photos</IconText>
                        </Link>
                        )
                      : undefined
                  }
                  <hr className='drowdown-divider my-1' />
                  <a className='dropdown-item has-text-strike'> {/* eslint-disable-line */}
                    <IconText icon={faExchange}>Switch Spaceships</IconText>
                  </a>
                  <a className='dropdown-item has-text-strike' onClick={handleDownloadMagazine}> {/* eslint-disable-line */}
                    <IconText icon={faBookOpen}>Download as Magazine</IconText>
                  </a>
                  {
                    !isPassengers && user.isAdmin
                      ? (
                      <Link to='/passengers' className='dropdown-item'> {/* eslint-disable-line */}
                        <IconText icon={faUsers}>Passengers</IconText>
                      </Link>
                        )
                      : undefined
                  }
                  <hr className='drowdown-divider my-1' />
                  <a className='dropdown-item' href='https://bottlle.com/terms-of-service' target='_blank' rel='noreferrer'>
                    <IconText icon={faGavel}>Terms of Service</IconText>
                  </a>
                  <a className='dropdown-item' href='https://bottlle.com/privacy-policy' target='_blank' rel='noreferrer'>
                    <IconText icon={faBalanceScaleRight}>Privacy Policy</IconText>
                  </a>
                  <hr className='drowdown-divider my-1' />
                  <Button className='dropdown-item' onClick={user.logout}> {/* eslint-disable-line */}
                    <IconText icon={faSignOut}>Logout</IconText>
                  </Button>
                  <hr className='drowdown-divider my-1' />
                  <div className='dropdown-item' style={{ fontSize: '0.6rem', paddingTop: 0, paddingBottom: 0 }}>
                    ©{currentYear} Bottlle LLC, All Rights Reserved
                  </div>
                </>
              }
            >
              <button className='button is-white'>
                <span className='app-screen__profile-pic'>
                  <FontAwesomeIcon icon={faUserCircle} />
                </span>
                <span className='app-screen__user-name'>
                  {screenSize.isMobile ? user.firstName : user.name}
                </span>
              </button>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className={classNames('hero-body app-screen__hero-body', bodyClassName)}>
        {children}
      </div>
    </div>
  )
})

export default AppScreen

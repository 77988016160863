import { observer } from 'mobx-react-lite'
import AppScreen from './AppScreen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import SpaceshipUser from './SpaceshipUser'
import AddPassengerForm from './AddPassengerForm'

import spaceshipUsers from '../observables/spaceship-users'
import user from '../observables/user'

const PassengersScreen = observer(() => {
  const users = spaceshipUsers.users.map((user, index) => (<SpaceshipUser passenger={user} key={index} />))

  return (
    <AppScreen bodyClassName='passengers-screen__hero-body'>
      <div className='container'>
        <div className='columns is-centered'>
          <div className='column is-6'>
            <p className='has-text-white title is-4 is-flex is-justify-content-space-between passengers-screen__main-title'>
              <span>Passengers</span>
              <button className='button is-white is-outlined' onClick={() => spaceshipUsers.setAddFormState()} onFocus={ev => ev.target.blur()}>
                <span className='icon'><FontAwesomeIcon icon={faPlus} /></span>
                <span>Add</span>
              </button>
            </p>
            {user.permissionLevel <= 2 ? <AddPassengerForm /> : null}
            {users}
          </div>
        </div>
      </div>
    </AppScreen>
  )
})

export default PassengersScreen

import { observer } from 'mobx-react-lite'

import AppScreen from './AppScreen'
import album from '../observables/album'
import classNames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons'
import { faTriangle } from '@fortawesome/pro-solid-svg-icons'

import Sprockets from './Sprockets'
import Image from './images/Image'
import { useSwipeable } from 'react-swipeable'
import { useState } from 'react'
import screenSize from '../observables/screen-size'

const AlbumScreen = observer(() => {
  const [swipeDelta, setSwipeDelta] = useState(0)

  const swipeHandlers = useSwipeable({
    onSwiping: (event) => {
      setSwipeDelta(event.absX > event.absY ? event.deltaX : 0)
    },
    onSwiped: (event) => {
      setSwipeDelta(0)
      const friction = 1
      const finalDelta = Math.abs(event.deltaX + event.vxvy[0] * event.vxvy[0] / 2 / friction)
      if (finalDelta > screenSize.fontSize) {
        if (event.dir === 'Left') album.forward()
        if (event.dir === 'Right') album.backward()
      }
    }
  })

  const isMobile = screenSize.width <= 768

  if (album.loading) {
    return (
      <AppScreen>
        <div className='container has-text-centered'>
          <FontAwesomeIcon icon={faCircleNotch} size='5x' spin />
        </div>
      </AppScreen>
    )
  }

  if (!album.currentPic && !album.isEndCard) {
    return (
      <AppScreen>
        <div className='container has-text-centered'>
          404: image not found
        </div>
      </AppScreen>
    )
  }

  return (
    <AppScreen bodyClassName='album-screen__hero-body'>
      <div className='album-screen__carousel' {...swipeHandlers}>
        {
          new Array(11).fill().map((_value, index) => {
            const imageId = album.picId + index - 5

            if (imageId < 0) return null
            if (imageId > album.pics.length) return null

            if (imageId === album.pics.length) return null // TODO add end card here

            return (
              <div
                className={classNames('album-screen__image-container', { 'album-screen__image-container--swiping': swipeDelta })}
                key={`image-container-${imageId}`}
                style={{ transform: `translateX(${(index - 5) * 100}%) translateX(${swipeDelta}px)` }}
              >
                <Image pic={album.pics[imageId]} />
              </div>
            )
          })
        }
      </div>
      <div
        className={classNames('album-screen__nav-left', { 'album-screen__nav-left--hidden': album.picId === 0 })}
        onClick={album.backward} // eslint-disable-line
      >
        <div className='album-screen__nav-left-text'>
          <FontAwesomeIcon icon={faTriangle} />
          <span className='mx-2 has-text-weight-bold'>{isMobile ? 'SWIPE' : 'PREVIOUS'}</span>
          <FontAwesomeIcon icon={faTriangle} />
        </div>
      </div>
      <div
        className={classNames('album-screen__nav-right', { 'album-screen__nav-right--hidden': album.picId === album.pics.length })}
        onClick={album.forward} // eslint-disable-line
      >
        <div className='album-screen__nav-right-text'>
          <FontAwesomeIcon icon={faTriangle} />
          <span className='mx-2 has-text-weight-bold'>{isMobile ? 'SWIPE' : 'NEXT'}</span>
          <FontAwesomeIcon icon={faTriangle} />
        </div>
      </div>
      {
        isMobile
          ? undefined
          : (
            <div className='album-screen__foot-section'>
              <Sprockets />
            </div>
            )
      }
    </AppScreen>
  )
})

export default AlbumScreen

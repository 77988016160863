import { observer } from 'mobx-react-lite'
import screenSize from '../../observables/screen-size'

import MobileImage from './MobileImage'
import TallImage from './TallImage'
import WideImage from './WideImage'

const Image = observer(({ pic, ...rest }) => {
  if (screenSize.width <= 768 || screenSize.height <= 768) return <MobileImage pic={pic} {...rest} />

  const tallHorizontalSpace = screenSize.width - 37 * screenSize.fontSize
  const tallVerticalSpace = screenSize.height - 8.5 * screenSize.fontSize

  const wideHorizontalSpace = screenSize.width
  const wideVerticalSpace = screenSize.height - 30.5 * screenSize.fontSize

  // const mobileHorizontalSpace = screenSize.width
  // const mobileVerticalSpace = screenSize.height - (screenSize.width <= 768 ? 11 : 14.5) * screenSize.fontSize

  const tallImageWidth = Math.min(tallHorizontalSpace, tallVerticalSpace * pic.aspectRatio)
  const wideImageWidth = Math.min(wideHorizontalSpace, wideVerticalSpace * pic.aspectRatio)

  if (tallImageWidth > wideImageWidth) return <TallImage pic={pic} {...rest} />
  else return <WideImage pic={pic} {...rest} />
})

export default Image

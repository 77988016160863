import { observer } from 'mobx-react-lite'
import screenSize from '../../observables/screen-size'
import CommentSection from '../CommentSection'
import Favorites from '../Favorites'
import ActualImage from './ActualImage'
import ImageTitle from '../ImageTitle'

const WideImage = observer(({ pic }) => {
  const wideHorizontalSpace = screenSize.width
  const wideVerticalSpace = screenSize.height - 30.5 * screenSize.fontSize

  const width = Math.min(wideHorizontalSpace, wideVerticalSpace * pic.aspectRatio)
  const height = width / pic.aspectRatio

  const commentSectionWidth = Math.min(width, screenSize.width - 10 * screenSize.fontSize)

  return (
    <div className='wide-image'>
      <div className='wide-image__image-area'>
        <ActualImage width={width} height={height} pic={pic} />
      </div>
      <div className='wide-image__comment-section' style={{ width: `${commentSectionWidth}px` }}>
        <div className='wide-image__label-row'>
          <Favorites className='wide-image__label-favorites' pic={pic} showText darkMode />
          <div className='wide-image__label'>
            <div className='wide-image__title'>
              <ImageTitle
                imageId={pic.iid}
                imageAuthor={pic.postedby}
                currentTitle={pic.title}
                userCanDelete={pic.cdi}
              />
            </div>
            <div className='wide-image__author'>{pic.postedby}</div>
            <div className='wide-image__date'>
              {new Date(pic.postedon).toLocaleDateString('en-us', { day: 'numeric', month: 'long', year: 'numeric', weekday: 'long' })}
            </div>
          </div>
        </div>
        <CommentSection pic={pic} darkMode />
      </div>
    </div>
  )
})

export default WideImage

import { faArrowUp } from '@fortawesome/pro-light-svg-icons'
import classNames from 'classnames'
import MurmurHash3 from 'imurmurhash'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import album from '../observables/album'
import Button from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextareaAutosize from 'react-textarea-autosize'

import Comment from './Comment'

const commentSuggestions = [
  'Cool shot!',
  'Cool shot!',
  'Cool shot!',
  'Cool shot!',
  'I love this pic',
  'I love this pic',
  'I love this pic',
  'I love this pic',
  'Wish you were there',
  'Wish you were there',
  'Wish you were there',
  'Wish you were there',
  'That was an amazing day',
  'That was an amazing day',
  'That was an amazing day',
  'That was an amazing day',
  'Remember this moment forever',
  'Remember this moment forever',
  'Remember this moment forever',
  'Remember this moment forever',
  'You rock!',
  'You rock!',
  'You rock!',
  'You rock!',
  'Is that Bigfoot in the background?'
]

const errorMap = {
  err2: 'Your session has expired (e2)',
  err3: 'An error occured while attempting to update the comment (e3)',
  err4: 'An error occured while attempting to update the comment (e3)'
}

const suggest = url => commentSuggestions[Math.floor(MurmurHash3(url.split('').reverse().join('')).result() / 0xFFFFFFFF * commentSuggestions.length)]

const CommentSection = observer(({ pic, className, horizontal, header, darkMode, noPadding }) => {
  const [comment, setComment] = useState('')
  const buttonRef = useRef()
  const editingInput = useRef()

  const handleSend = async () => {
    await album.addComment(pic.iid, comment)
    setComment('')
  }

  const handleKeypress = (event) => {
    if (event.key === 'Enter' && event.ctrlKey) buttonRef.current.click()
  }

  const handleOnHeightChange = (height, { rowHeight }) => {
    const valueRows = editingInput.current.scrollHeight / rowHeight
    const displayedRows = height / rowHeight

    if (displayedRows < valueRows) {
      editingInput.current.style.height = `${Math.ceil(valueRows * rowHeight)}px`
    }
  }

  return (
    <div
      className={classNames(className, 'comment-section', {
        'comment-section--horizontal': horizontal,
        'comment-section--dark-mode': darkMode
      })}
    >
      {
        header && (
          <div className='comment-section__header'>
            {header}
          </div>
        )
      }
      <div className='comment-section__comments-container'>
        <div className={classNames('comment-section__comments', { 'comment-section__comments--no-padding': noPadding })}>
          {
            pic.comments.length
              ? (
                <>
                  <div className='comment-section__filler' />
                  {
                    [...pic.comments].reverse().map((comment, index) => <Comment comment={comment} key={index} imageId={pic.iid} />)
                  }
                </>
                )
              : (
                <div className='comment-section__nocomment'>
                  <div className='comment-section__nocomment-message'>{album.funModeEnabled ? 'Aww... no comments here yet' : 'No comments here'}</div>
                  <div className='comment-section__nocomment-suggestion'>{album.funModeEnabled ? 'Wanna help?' : ''}</div>
                </div>
                )
          }
        </div>
      </div>
      <div className={classNames('comment-section__new-comment', { 'mr-4': !noPadding })}>
        <div className='field has-addons is-align-items-flex-end'>
          <div className='control is-expanded'>
            <TextareaAutosize
              className={classNames('textarea comment-section__comment-field', { 'comment-section__comment-field--dark-mode': darkMode })}
              placeholder={album.funModeEnabled ? suggest(pic.url) : 'Add your comment here'}
              value={comment}
              onChange={ev => setComment(ev.target.value)}
              onKeyPress={handleKeypress}
              maxLength={5000}
              onHeightChange={handleOnHeightChange}
              ref={editingInput}
            />
          </div>
          <div className='control'>
            <Button
              className={classNames('comment-section__send-button', { 'is-outlined is-white': darkMode })}
              buttonRef={buttonRef}
              errorMap={errorMap}
              onClick={handleSend}
            >
              <FontAwesomeIcon icon={faArrowUp} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default CommentSection

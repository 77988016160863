import { faArrowCircleUp, faCheck, faCheckCircle, faCircleNotch, faExclamationCircle, faPlusCircle, faTrash, faUpload } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Mutex } from 'async-mutex'
import classNames from 'classnames'
import heic2any from 'heic2any'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'
import { useEffect, useState } from 'react'
import album from '../observables/album'
import imageUploads from '../observables/image-uploads'
import screenSize from '../observables/screen-size'
import user from '../observables/user'
import { apiKey, apiUrl } from '../utils/api-call'
import { findEXIFinHEIC } from '../vendor/exif-heic'
import AppScreen from './AppScreen'
import Button from './Button'
import IconText from './IconText'
import { Reorder, Reorderable, ReorderContainer } from './Reorder'

const fileTypes = 'image/jpg,image/jpeg,image/png,image/heic'.split(',')
const fileExtensions = { heic: 'image/heic' }
const extensionWhitelist = Object.keys(fileExtensions)

const fileInput = document.createElement('input')
fileInput.type = 'file'
fileInput.accept = fileTypes.join(',')
fileInput.multiple = true

const originalImageThreshold = 512 * 1024

const conversionMutex = new Mutex()

const processFiles = fileList => {
  const approvedFiles = Array.from(fileList).filter(file => {
    if (fileTypes.includes(file.type)) {
      file.adjustedType = file.type
      return true
    }

    const extension = (file.name.match(/\.\w+$/) || [''])[0].slice(1).toLowerCase()
    if (!extensionWhitelist.includes(extension)) return false

    file.adjustedType = fileExtensions[extension]
    return true
  })

  for (const file of approvedFiles) {
    file.registrationId = imageUploads.registerImage(file.name, file.adjustedType)
  }

  approvedFiles.forEach(async file => {
    if (file.adjustedType === 'image/heic') {
      const buffer = await file.arrayBuffer()
      const metadata = await Promise.resolve((async () => findEXIFinHEIC(buffer))()).catch(() => null)

      const wrongOrientation = [8, 6, 5, 7].includes(metadata.Orientation)
      const [width, height] = wrongOrientation ? [metadata.PixelYDimension, metadata.PixelXDimension] : [metadata.PixelXDimension, metadata.PixelYDimension]

      imageUploads.registerDimensions(file.registrationId, width, height)

      const conversion = await conversionMutex.runExclusive(() => heic2any({ blob: file, toType: 'image/jpeg', quality: 0.75 }))
      // TODO downscale image if needed
      imageUploads.registerData(file.registrationId, conversion, 'image/jpeg')
    }

    if (['image/jpg', 'image/jpeg', 'image/png'].includes(file.adjustedType)) {
      const image = await conversionMutex.runExclusive(() => new Promise((resolve, reject) => {
        const image = new window.Image()
        const url = window.URL.createObjectURL(file)

        image.onerror = reject
        image.onload = () => {
          window.URL.revokeObjectURL(url)
          resolve(image)
        }

        image.src = url
      }))

      imageUploads.registerDimensions(file.registrationId, image.width, image.height)

      if (file.size < originalImageThreshold) {
        imageUploads.registerData(file.registrationId, file, 'image/jpeg') // TODO check dimensions
      } else {
        const dataUrl = await new Promise((resolve, reject) => {
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          const context = canvas.getContext('2d')
          context.drawImage(image, 0, 0)

          // TODO this would be a good time to resize the image if necessary

          setImmediate(async () => {
            const dataUrl = canvas.toDataURL('image/jpeg', 0.75)
            resolve(dataUrl)
          })
        })

        const result = await window.fetch(dataUrl)
        const blob = await result.blob()

        imageUploads.registerData(file.registrationId, blob, 'image/jpeg')
      }
    }
  })
}

const uploadConcurrency = 2
const uploadMutexes = new Array(uploadConcurrency).fill().map(() => new Mutex())

const uploadImage = async (registrationId, index, sequence) => {
  const mutex = uploadMutexes[index % uploadMutexes.length]

  imageUploads.updateUpload(registrationId, 'waiting', 0)

  const imageSize = imageUploads.store[registrationId].size

  await mutex.runExclusive(() => new Promise((resolve, reject) => {
    imageUploads.updateUpload(registrationId, 'uploading', 0)

    const xhr = new window.XMLHttpRequest()

    xhr.upload.addEventListener('progress', event => {
      imageUploads.updateUpload(registrationId, 'uploading', Math.min(Math.max(event.loaded / imageSize, 0), 1))
    })

    const formData = new window.FormData()
    formData.append('pc', `WEBPSS.IMAGE.PS.W1.${apiKey}`)
    formData.append('sid', user.sid)
    formData.append('seq', sequence)
    formData.append('webpssImage', imageUploads.store[registrationId])

    xhr.addEventListener('load', () => {
      imageUploads.updateUpload(registrationId, 'done', 1)
      resolve()
    })
    xhr.addEventListener('error', error => {
      console.error('Error while uploading image', error)
      reject(new Error('Error while uploading image'))
    })

    xhr.open('POST', apiUrl)
    xhr.send(formData)
  }))
}

const uploadAll = async () => {
  imageUploads.startUpload()

  const sequenceBase = new Date().getTime() * 1000

  await Promise.all(imageUploads.sortedImages.map((image, index) => {
    return uploadImage(image.id, index, sequenceBase + image.sequence)
  }))

  await imageUploads.finishUpload()
}

fileInput.addEventListener('change', () => processFiles(fileInput.files))

const UploadScreen = observer(() => {
  const openFileInput = () => fileInput.click()

  const handleDrop = (event) => processFiles(event.dataTransfer.files)

  const history = useHistory()
  const handleUpload = async () => {
    const lastPic = album.pics.length

    await uploadAll()

    history.push(`/pic/${lastPic + 1}`)
  }

  const [deleteMode, setDeleteMode] = useState(false)
  const toggleDelete = () => setDeleteMode(!deleteMode)

  useEffect(() => {
    if (deleteMode && !imageUploads.sortedImages.length) setDeleteMode(false)
  }, [deleteMode, imageUploads.sortedImages.length]) // eslint-disable-line

  const handleReorder = (newOrder) => {
    imageUploads.reorderImages(newOrder)
  }

  return (
    <AppScreen bodyClassName='upload-screen__hero-body'>
      <div className='upload-screen__upload-area' onDrop={handleDrop}>
        <Reorder alwaysOn className='upload-screen__photos' onReorder={handleReorder}>
          <ReorderContainer>
            {
              imageUploads.sortedImages.map(image => {
                const longerDimension = screenSize.fontSize * (screenSize.width <= 768 ? 6 : 7)
                const width = image.aspectRatio < 1 ? longerDimension * image.aspectRatio : longerDimension
                const height = image.aspectRatio > 1 ? longerDimension / image.aspectRatio : longerDimension

                const reorderableSize = screenSize.width <= 768 ? '8em' : '8.75em'

                return (
                  <Reorderable width={reorderableSize} height={reorderableSize} key={image.id}>
                    <div className='upload-screen__photo-wrapper'>
                      <div className={classNames('upload-screen__photo-border', { 'upload-screen__photo-border--hidden': !image.dimensionsPresent })}>
                        <div className='upload-screen__photo' style={{ width: `${width}px`, height: `${height}px` }}>
                          {
                            image.dataPresent
                              ? <img className='upload-screen__photo-actual' src={image.url} alt='' />
                              : <FontAwesomeIcon icon={faCircleNotch} spin />
                          }
                        </div>
                      </div>
                      {
                        imageUploads.uploading
                          ? (
                            <div className='upload-screen__photo-overlay'>
                              <div className='upload-screen__photo-overlay-icon'>
                                <FontAwesomeIcon
                                  icon={[faCircleNotch, faArrowCircleUp, faCheckCircle, faExclamationCircle][['uploading', 'done', 'error'].indexOf(image.uploadState) + 1]}
                                  size='2x'
                                  spin={!['uploading', 'done', 'error'].includes(image.uploadState)}
                                />
                              </div>
                              <div className='upload-screen__photo-overlay-text'>
                                {({
                                  uploading: 'Uploading...',
                                  done: 'Done',
                                  error: 'Error'
                                })[image.uploadState] || 'Waiting...'}
                              </div>
                              <div className='upload-screen__photo-overlay-progress'>
                                <progress className='progress is-white is-small' value={!['uploading', 'done', 'error'].includes(image.uploadState) ? NaN : image.uploadRatio * 100} max={100} />
                              </div>
                            </div>
                            )
                          : undefined
                      }
                      <div className={classNames('upload-screen__delete-overlay', { 'is-hidden': !deleteMode })}>
                        <Button className='upload-screen__delete-button is-outlined is-small is-white' onClick={() => imageUploads.deleteImage(image.id)}>
                          <IconText icon={faTrash}>Remove</IconText>
                        </Button>
                      </div>
                    </div>
                  </Reorderable>
                )
              })
            }
          </ReorderContainer>
        </Reorder>
        <div className='upload-screen__upload-button'>
          <Button onClick={openFileInput}>
            <IconText icon={faPlusCircle}>Add Photos</IconText>
          </Button>
          {
            imageUploads.sortedImages.length
              ? (
                <Button onClick={toggleDelete} className='is-small mt-3'>
                  <IconText icon={deleteMode ? faCheck : faTrash}>{deleteMode ? 'Done Removing Photos' : 'Remove Photos'}</IconText>
                </Button>
                )
              : undefined
          }
        </div>
      </div>
      <div className='upload-screen__upload-row'>
        <div className='upload-screen__progress-bar-area'>
          <progress className='progress' value={imageUploads.progress * 100} max={100}>{imageUploads.progress * 100}%</progress>
        </div>
        <div className='upload-screen__upload-button-area'>
          <Button onClick={handleUpload}>
            <IconText icon={faUpload}>Upload</IconText>
          </Button>
        </div>
      </div>
    </AppScreen>
  )
})

export default UploadScreen

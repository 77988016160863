import { makeAutoObservable, autorun } from 'mobx'
import apiCall, { APIError } from '../utils/api-call'
import exposeDebug from '../utils/expose-debug'

const user = makeAutoObservable({
  firstName: '',
  lastName: '',
  get name () { return `${this.firstName} ${this.lastName}` },
  get canDeleteComments () { return (this.permissionLevel === 1) || (this.permissionLevel === 2) },
  permissionLevel: 0,
  // permission levels:
  //  1: Bottlle Owner (Full rights to all features)
  //  2: Admin
  //  3: View, Upload & Comment
  //  4: View & Comment
  //  5: View Only
  get canUpload () { return this.permissionLevel <= 3 },
  get isAdmin () { return this.permissionLevel <= 2 },
  uid: null,
  sid: null,
  loading: true,
  get loggedIn () { return Boolean(this.sid) },
  * login (email, uid) {
    const result = yield apiCall('WEBPSS.LGPW.PT.W1', {
      e: email,
      uid: uid
    })

    const retrievedUser = result[0]

    this.firstName = retrievedUser.f
    this.lastName = retrievedUser.l
    this.permissionLevel = Number(retrievedUser.p)
    this.sid = retrievedUser.sid
    this.uid = uid
  },
  * logout () {
    try {
      yield apiCall('WEBPSS.LGPW.DL.W1', { sid: user.sid })
    } catch (err) {
      // don't care, we're logging out
    }

    this.firstName = ''
    this.lastName = ''
    this.permissionLevel = 0
    this.sid = null
    this.uid = null
  },
  * restore () {
    try {
      const loginData = JSON.parse(window.localStorage.getItem('spaceship-login-data'))

      yield apiCall('WEBPSS.PING.PT.W1', { sid: loginData.sid })

      this.firstName = loginData.firstName
      this.lastName = loginData.lastName
      this.permissionLevel = loginData.permissionLevel
      this.sid = loginData.sid
      this.uid = loginData.uid
    } catch (err) {
      if (err instanceof APIError) {
        console.error('cannot restore last session:', err)
      }
      window.localStorage.removeItem('spaceship-login-data')
    }

    this.loading = false
  }
}, null, { autoBind: true })

let lastInterval = null

autorun(() => {
  if (lastInterval) clearInterval(lastInterval)

  if (user.loggedIn) {
    lastInterval = setInterval(async () => {
      try {
        await apiCall('WEBPSS.PING.PT.W1', { sid: user.sid })
      } catch (err) {
        if (err.code === 'err2') user.logout()
      }
    }, 15000)
  }
})

let wasLoggedIn = false

autorun(() => {
  if (!window.localStorage) return

  if (!user.loggedIn) {
    if (wasLoggedIn) window.localStorage.removeItem('spaceship-login-data')
    return
  }

  const loginData = JSON.stringify({
    firstName: user.firstName,
    lastName: user.lastName,
    permissionLevel: user.permissionLevel,
    sid: user.sid,
    uid: user.uid
  })

  window.localStorage.setItem('spaceship-login-data', loginData)
  wasLoggedIn = true
})

user.restore()

exposeDebug('observables:user', user)

export default user

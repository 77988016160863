import { faCommentAltLines, faHeart as emptyHeart, faStar as emptyStar, faCircleNotch } from '@fortawesome/pro-light-svg-icons'
import { faHeart as solidHeart, faStar as solidStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import album from '../observables/album'
import Button from './Button'

const Favorites = observer(({ pic, maxHearts = 5, showText, showComments, className, vertical, darkMode, onClick }) => {
  const otherFaveCount = Math.max(pic.favoritecount - !!pic.favorite, 0)
  const [isLoading, setLoading] = useState(false)
  const displayedHearts = Math.min(otherFaveCount, album.funModeEnabled ? maxHearts : 0)
  const omittedHearts = otherFaveCount - displayedHearts

  const [solidIcon, emptyIcon] = album.funModeEnabled ? [solidHeart, emptyHeart] : [solidStar, emptyStar]

  const errorMap = {
    err2: 'Your session has expired (e2)',
    err3: 'An error occured while attempting to update your favorite (e3)',
    err4: 'An error occured while attempting to update your favorite (e4)',
    err5: 'An error occured while attempting to update your favorite (e5)',
    err6: 'An error occured while attempting to update your favorite (e6)'
  }

  const handleFavorite = async (event) => {
    event.stopPropagation()
    event.preventDefault()

    setLoading(true)

    if (pic.favorite) {
      await album.unFavorImage(pic.iid)
    } else {
      await album.favorImage(pic.iid)
    }

    setLoading(false)
  }

  return (
    <div
      className={classNames('favorites', className, {
        'favorites--vertical': vertical,
        'favorites--fun': album.funModeEnabled,
        'favorites--dark-mode': darkMode
      })}
      onClick={onClick}
    >
      <Button
        className={classNames('favorites__own', { 'favorites__own--active': pic.favorite })}
        onClick={handleFavorite}
        errorMap={errorMap}
      >
        {
          isLoading
            ? <FontAwesomeIcon className='favorites__own-heart' icon={faCircleNotch} spin />
            : <FontAwesomeIcon className='favorites__own-heart' icon={pic.favorite ? solidIcon : emptyIcon} />
        }
      </Button>
      <div className='favorites__others'>
        {
          displayedHearts
            ? new Array(displayedHearts).fill().map((_v, index) => <FontAwesomeIcon className='favorites__other-heart' icon={solidIcon} key={index} />)
            : undefined
        }
        {
          omittedHearts
            ? (
              <div className='favorites__omitted-hearts'>
                +{omittedHearts}
              </div>
              )
            : undefined
        }
      </div>
      <div className={classNames('favorites__filler', { 'has-text-right': !showComments })}>
        {
          showText ? `${pic.favoritecount} ${pic.favoritecount === 1 ? 'favorite' : 'favorites'}` : undefined
        }
      </div>
      {
        showComments
          ? (
            <div className={classNames('favorites__comments', { 'favorites__comments--empty': !pic.comments.length, 'favorites__comments--dark-mode': darkMode })}>
              <FontAwesomeIcon icon={faCommentAltLines} />
              {
                pic.comments.length
                  ? (
                    <div className='favorites__comments-blip'>
                      {pic.comments.length}
                    </div>
                    )
                  : undefined
              }
            </div>
            )
          : undefined
      }
    </div>
  )
})

export default Favorites

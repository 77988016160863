import React from 'react'
import { Link } from 'react-router-dom'

const footer = props => {
  const currentYear = new Date().getFullYear()

  return (
    <div className='hero-foot'>
      <footer className='footer'>
        <div className='has-text-centered is-size-7'>
          <p className='is-inline-block-tablet is-block-mobile'>
            <Link className='has-text-spaceship-grey' to='/privacy-policy'>Privacy&nbsp;Policy</Link>&nbsp;•&nbsp;
            <Link className='has-text-spaceship-grey' to='/terms-of-service'>Terms&nbsp;of&nbsp;Service</Link>
            <span className='is-inline-tablet is-hidden-mobile'>&nbsp;•&nbsp;</span>
          </p>
          <p className='is-inline-block-tablet is-block-mobile'>©{currentYear} Bottlle LLC, All Rights Reserved</p>
        </div>
      </footer>
    </div>
  )
}

export default footer

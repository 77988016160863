import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'

const Dropdown = ({ children, menu, className, ...props }) => {
  const [isOpen, setOpen] = useState(false)
  const outerRef = useRef()
  const innerRef = useRef()

  useEffect(() => {
    const handler = (event) => {
      let target = event.target
      while (target) {
        if (target === (isOpen ? innerRef : outerRef).current) return
        target = target.parentNode
      }

      // if none of the parents were ref, the click originates from outside the dropdown, so close it
      setOpen(false)
    }

    document.body.addEventListener('click', handler)
    return () => document.body.removeEventListener('click', handler)
  }, [isOpen])

  return (
    <div className={classNames(className, 'dropdown', { 'is-active': isOpen })} ref={outerRef} {...props}>
      <div className='dropdown-trigger' onClick={() => setOpen(true)}>
        {children}
      </div>
      <div className='dropdown-menu' ref={innerRef}>
        <div className='dropdown-content'>
          {menu}
        </div>
      </div>
    </div>
  )
}

export default Dropdown

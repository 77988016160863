import { faCheck, faUndo, faPencilAlt, faUserCircle, faTrashAlt, faTimes, faExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import TextareaAutosize from 'react-textarea-autosize'

import album from '../observables/album'
import user from '../observables/user'

import Button from './Button'
import useModal from './useModal'
import { useRef, useState } from 'react'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons'

const Comment = observer(({ className, comment, imageId }) => {
  const [commentContent, setComment] = useState(comment.cmt)
  const [editingMode, setEditingMode] = useState(false)
  const [menuState, setMenuState] = useState(false)
  const [modal, showModal] = useModal()
  const editingInput = useRef()
  const saveButtonRef = useRef()

  const commentTimestamp = new Date(comment.postedon).toLocaleString('en-us', { weekday: 'short', day: 'numeric', month: 'short', hour: 'numeric', minute: '2-digit' })

  const errorMap = {
    err2: 'Your session has expired (e2)',
    err3: 'An error occured while attempting to update the comment (e3)',
    err4: 'An error occured while attempting to update the comment (e4)',
    err5: 'An error occured while attempting to update the comment (e5)',
    err6: 'An error occured while attempting to update the comment (e6)'
  }

  const errorMap2 = {
    err2: 'Your session has expired (e2)',
    err3: 'An error occured while attempting to report this comment (e3)',
    err4: 'An error occured while attempting to report this comment (e4)',
    err5: 'An error occured while attempting to report this comment (e5)',
    err6: 'An error occured while attempting to report this comment (e6)'
  }

  const handleCommentChange = ev => {
    setComment(ev.target.value)
  }

  const handleSetComment = async () => {
    await album.editComment(comment.cid, commentContent, imageId)
    setEditingMode(false)
  }

  const handleInputKeyPress = event => {
    if (event.key === 'Enter') saveButtonRef.current.click()
  }

  const handleStopEditing = () => {
    setEditingMode(false)
    setComment(comment.cmt)
  }

  const handleOnHeightChange = (height, { rowHeight }) => {
    const valueRows = editingInput.current.scrollHeight / rowHeight
    const displayedRows = height / rowHeight

    if (displayedRows < valueRows) {
      editingInput.current.style.height = `${Math.ceil(valueRows * rowHeight)}px`
    }
  }

  const handleStartEditing = async () => {
    await setEditingMode(true)
    setMenuState(false)
    editingInput.current.focus()
  }

  const handleOffensiveContentReport = async () => {
    const choice = await showModal(
      'Report Comment',
      `Are you sure you'd like to report this comment from ${comment.name}, posted on ${commentTimestamp}?`,
      [{
        text: 'No',
        response: 'no',
        className: 'modal-cancel',
        icon: faUndo,
        iconProps: {
          size: '2x'
        },
        isCancel: true
      }, {
        text: 'Yes',
        className: 'is-dark',
        response: 'yes',
        icon: faCheck,
        iconProps: {
          size: '2x'
        }
      }]
    )
    if (choice === 'no') return
    await album.offensiveContentReport(imageId, comment.cid)
    await showModal(
      'Content Report Submitted',
      `The comment ${comment.name} posted on ${commentTimestamp} was successfully reported to the owner of this Photo Spaceship!`,
      [{ text: 'OK', className: 'is-dark', response: 'ok', icon: faCheck, iconProps: { size: '2x' } }]
    )
    setMenuState(false)
  }

  const handleDelete = async () => {
    const choice = await showModal(
      'Delete Comment',
      `Are you sure you'd like to delete this comment from ${comment.name}, posted on ${commentTimestamp}?`,
      [{
        text: 'No',
        response: 'no',
        className: 'modal-cancel',
        icon: faUndo,
        iconProps: {
          size: '2x'
        },
        isCancel: true
      }, {
        text: 'Yes',
        className: 'is-dark',
        response: 'yes',
        icon: faCheck,
        iconProps: {
          size: '2x'
        }
      }]
    )
    if (choice === 'no') return
    await album.deleteComment(comment.cid, imageId)
  }

  const editingMarkup = (
    <>
      <TextareaAutosize
        className='has-text-white'
        value={commentContent}
        onChange={handleCommentChange}
        ref={editingInput}
        onKeyPress={handleInputKeyPress}
        maxLength={5000}
        onHeightChange={(height, opts) => handleOnHeightChange(height, opts)}
      />
      <div className={classNames('field is-grouped')}>
        <div className='control is-expanded has-standard-basis'>
          <button type='button' className='button is-outlined is-white is-fullwidth' onClick={handleStopEditing}>
            <span>Cancel</span>
            <span className='icon is-small'><FontAwesomeIcon icon={faTimes} /></span>
          </button>
        </div>
        <div className='control is-expanded has-standard-basis'>
          <Button
            className={classNames('is-outlined is-white is-fullwidth')}
            onClick={handleSetComment}
            buttonRef={saveButtonRef}
            errorMap={errorMap}
          >
            <span>Save</span>
            <span className='icon is-small'><FontAwesomeIcon icon={faCheck} /></span>
          </Button>
        </div>
      </div>
    </>
  )

  return (
    <div className={classNames(className, 'comment')}>
      <div className='comment__main is-relative'>
        <div className='comment__header is-flex is-justify-content-space-between'>
          <div className='comment__avatar__name is-flex'>
            <div className='comment__avatar-container is-flex-grow-0'>
              {
              comment.ppic
                ? (
                  <img className='comment__avatar' src={comment.ppicUrl} alt={comment.name} />
                  )
                : (
                  <FontAwesomeIcon icon={faUserCircle} className='comment__avatar-placeholder' />
                  )
            }
            </div>
            <div className='comment__name-row'>
              <div className='comment__name'>
                {comment.name}
              </div>
              <div className='comment__timestamp'>
                {commentTimestamp}
              </div>
            </div>
          </div>
          <div className='comment__menu is-flex-grow-0'>
            <div className='comment__trigger' onClick={() => setMenuState(!menuState)}>
              <span className='icon'><FontAwesomeIcon icon={faEllipsisV} /></span>
            </div>
            <div className={classNames('comment__menu-items is-flex is-flex-direction-column', { open: menuState })}>
              {
              comment.cec
                ? (
                  <button type='button' className='button is-outlined is-white is-borderless is-radiusless is-flex is-justify-content-space-between' onClick={handleStartEditing}>
                    <span>Edit</span>
                    <span className='icon is-small'><FontAwesomeIcon icon={faPencilAlt} /></span>
                  </button>
                  )
                : null
              }
              {
              comment.cec || user.canDeleteComments
                ? (
                  <Button
                    className={classNames('is-outlined is-white is-borderless is-radiusless is-flex is-justify-content-space-between')}
                    onClick={handleDelete}
                    errorMap={errorMap}
                  >
                    <span>Delete</span>
                    <span className='icon is-small'><FontAwesomeIcon icon={faTrashAlt} /></span>
                  </Button>
                  )
                : null
              }
              <Button
                className={classNames('is-outlined is-white is-borderless is-radiusless is-flex is-justify-content-space-between')}
                onClick={handleOffensiveContentReport}
                errorMap={errorMap2}
              >
                <span>Report</span>
                <span className='icon is-small'><FontAwesomeIcon icon={faExclamation} /></span>
              </Button>
            </div>
          </div>
        </div>
        <div className='comment__content'>
          {editingMode ? editingMarkup : comment.cmt}
        </div>
      </div>
      {modal}
    </div>
  )
})

export default Comment
